.wrapper {
  position: fixed;
  right: 0;
  top: 40px;
  width: calc(100% - 250px);
  height: calc(100% - 70px);
  display: flex;
  z-index: 5;
  background-color: rgb(0 0 0 / 50%);
  align-items: center;
  justify-content: center;
}

.productPrevPopup {
  width: 100vw;
  height: 100%;
  background-color: #fff;
  border-radius: 2px;
  padding: 0px;
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
}

.carting_head {
  display: flex;
  padding: 12px;
  height: 65px;
  width: 100%;
  position: relative;
}

.pageTitleSection {
  width: auto;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.pageLogoHolder {
  width: 40px;
  height: 40px;
  border-radius: 2px;
  overflow: hidden;
  float: left;
  margin-right: 15px;
}

.pageLogo {
  width: 100%;
  height: 100%;
}

.pageTitle {
  float: left;
  font-size: 18px;
  font-family: var(--robotoC);
  font-weight: 500;
}

.closeCart {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  color: rgba(255, 0, 0, 0.2);
  border-radius: 2px;
  font-family: var(--robotoC);
  width: 27px;
  height: 27px;
  cursor: pointer;
  top: 25px;
  right: 25px;
  font-size: 40px;
  background-color: #fff;
}

.previewDivider {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100% - 64px);
}

.divItem {
  width: 50%;
  height: 100%;
}

.itemImage {
  border-radius: 2px 0 0 2px;
  background-color: #fff;
  /* border-right: 1px solid rgba(255, 0, 0, 0.1); */
  width: 45%;
  padding: 25px 40px 60px 100px;
}

.itemImage img {
  width: auto !important;
  max-width: 100%;
  max-height: 100%;
}

.itemDetails {
  width: 55%;
  border-radius: 0 2px 2px 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px 100px 60px 40px;
}

.prev_title h2 {
  font-family: var(--robotoC);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  color: #012965;
  line-height: 24px;
  margin-bottom: 10px;
  text-align: right;
}

.prev_title p {
  font-family: var(--robotoC);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  color: #012965;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #012965;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

div.prev_price {
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
  padding: 10px 0;
}

.prev_price p {
  color: #012965;
}

/* .prev_price span {
  font-family: var(--robotoC);
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  color: #012965;
  line-height: 40px;
} */

.prev_price .Current_price {
  /* font-size: 20px; */
}

.prev_price .prev_price {
  font-size: 14px;
  margin-right: 50px;
}

.prev_price .prev_price span {
  text-decoration: line-through;
  font-size: 14px;
}

.prev_qty {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 0;
}

.prev_countBtn {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid rgba(255, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 20px;
}

.prev_countBtn:hover {
  color: #fff;
  background-color: #ff0000;
}

.prev_count {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  font-family: var(--robotoC);
  font-size: 20px;
  font-weight: 500;
  color: #012965;
}

.prev_add {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0 30px;
}

.select_type {
  font-family: var(--robotoC);
  font-style: normal;
  font-size: 16px;
  font-weight: 300;
  height: 42px;
  width: 250px;
  padding: 8px 20px;
  margin: 8px 0;
  display: inline-block;
  color: #012965;
  letter-spacing: 0.2px;
  border: 1px solid rgb(255 0 0 / 10%);
  box-sizing: border-box;
  border-radius: 2px;
}

.select_type:focus-visible {
  border: 1px solid rgb(255 0 0 / 30%);
  outline: none;
}

.prev_addButton {
  width: 250px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--robotoC);
  font-size: 16px;
  background-color: #ff0000;
  border: none;
  border-radius: 2px;
  margin-right: 20px;
  color: #fff;
  cursor: pointer;
}

.prev_addFav {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid rgba(255, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff0000;
  cursor: pointer;
}

.tabContainer {
  width: 100%;
  height: calc(100% - 227px);
}

.tabHead {
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  bottom: -1px;
}

.tabs {
  width: 33.33%;
  font-family: var(--robotoC);
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  background-color: rgb(1 41 101 / 5%);
  border: 1px solid rgb(1 41 101 / 20%);
  border-right: none;
  border-bottom: none;
  transition: all 250ms ease;
  color: #012965;
}

.tabs:first-child {
  border-radius: 2px 0 0 0;
}

.tabs:last-child {
  border-right: 1px solid rgb(1 41 101 / 20%);
  border-radius: 0 2px 0 0;
}

.tabs.active {
  background-color: #fff;
  border-bottom: none;
  border-left: 1px solid rgb(1 41 101 / 20%);
}

.tabBody {
  width: 100%;
  height: calc(100% - 48px);
  border: 1px solid rgb(1 41 101 / 20%);
  padding: 50px 100px;
  /* overflow-y: auto; */
  border-radius: 0 0 2px 2px;
}

.tabBody p {
  font-family: var(--robotoC);
  font-style: normal;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  color: #012965;
  text-align: left;
}

.item_description {
  font-family: var(--robotoC);
  font-style: normal;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  color: #012965;
  width: 100%;
  height: auto;
  ;
  border: none;
  /* overflow-y: auto; */
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.text_area_view {
  font-family: var(--Poppins);
  font-style: normal;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  color: #012965;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
}
.text_area_view:focus-visible {
  outline: none;
}

._description {
  font-family: var(--Poppins);
  font-style: normal;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  color: #012965;
  width: 100%;
  height: 100%;
  ;
  border: none;
  overflow-y: auto;
}

.img_previewContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

._image_view {
  max-width: 100%;
  max-height: 100%;
}

.read_more_btn_cont {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.read_more_btn {
  color: #012965;
  font-size: 16px;
  font-family: var(--robotoC);
  cursor: pointer;
}

/* .tabBody textarea:focus-visible {
  border: none;
  outline: none;
} */

.ukbdTab {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.ukbdTabDivider {
  height: auto;
  width: 100%;
}

.ukbdTabDivider p {
  text-align: center;
}

/* .ukbdTabDivider:first-child {
  padding-right: 10px;
}
.ukbdTabDivider:last-child {
  width: 50%;
  border-left: 1px solid rgb(1 41 101 / 20%);
} */

.applinks {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.applink {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  height: 32px;
  width: 120px;
  border-radius: 2px;
  /* border: 1px solid rgb(1 41 101 / 20%); */
  cursor: pointer;
  color: #012965;
}

.appIcon {
  width: 28px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.appTexts {
  width: calc(100% - 32px);
}

.appTexts .label {
  font-family: var(--robotoC);
  font-size: 12px;
  font-weight: 300;
  width: 100%;
  display: block;
  line-height: 14px;
}

.appTexts .name {
  font-family: var(--robotoC);
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  display: block;

}

.supportlinks {
  padding: 18px 0 13px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.supportNumber p {
  font-family: var(--robotoC);
  text-align: right;
  color: #012965;
}

.supportMail p {
  font-family: var(--robotoC);
  text-align: right;
  color: #012965;
}

.sociallinks {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.socialicon {
  width: 40px;
  height: 40px;
  border-radius: 2px;
  /* border: 1px solid rgb(1 41 101 / 20%); */
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;
  margin-left: 10px;
  cursor: pointer;
}

.socialicon:hover {
  background-color: rgb(1 41 101 / 10%);
}

.previewBottomLine {
  width: 100%;
  height: auto;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  border-radius: 2px;
}

.bottomSection {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #012965;
  min-height: 20px;
}

.payIcons {
  width: 30px;
  height: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: center;
}

.payIcons.delivery {
  background-image: url(../images/pay/1-hour.webp);
}

.payIcons.aexpress {
  background-image: url(../images/pay/Amex.webp);
}

.payIcons.master {
  background-image: url(../images/pay/mastercard.webp);
}

.payIcons.visa {
  background-image: url(../images/pay/VIsa.webp);
}

.payIcons.bkash {
  background-image: url(../images/pay/bkash.webp);
}

.payIcons.nagad {
  background-image: url(../images/pay/NagadLogo.webp);
}

.payIcons.cod {
  background-image: url(../images/pay/COD.webp);
}


.topBox {
  border: 1px solid rgb(1 41 101 / 20%);
  padding: 0 25px;
  display: flex;
  flex-direction: column;
}

.productOrigin {
  width: 100%;
  /* padding: 0 25px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 0;
  float: left;
}

.originLogo {
  width: 50%;
  height: 24px;
  display: flex;
  align-items: center;
  position: relative;
}

.originLogo:first-child {
  justify-content: flex-start;
  /* border-right: 1px solid rgb(1 41 101 / 20%); */
}

/* .originLogo:last-child {
  justify-content: flex-end;
} */

.originLogo img {
  max-width: 100%;
  max-height: 24px;
}

.originLogo span {
  text-transform: uppercase;
  font-size: 8px;
  font-weight: 500;
  color: #012965;
  font-family: var(--robotoC);
  position: absolute;
  top: -10px;
  letter-spacing: 0.8px;
}

.originPay {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.vatTaxLabel {
  text-transform: capitalize;
  font-size: 11px;
  font-weight: 500;
  color: #012965;
  font-family: var(--robotoC);
  position: relative;
  letter-spacing: 0.8px;
  float: left;
  padding: 0;
  top: -26px;
}

.writeReview {
  font-size: 15px;
  color: #012965;
  font-family: var(--robotoC);
  cursor: pointer;
}

.writeReview:hover {
  text-decoration: underline;
}

.review_popup {
  position: absolute;
  z-index: 99999;
  background-color: #fff;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 30px 100px;
  display: flex;
}

.review_container {
  width: calc(100% - 120px);
  height: 100%;
  overflow: hidden;
}

.review_title_section {
  width: 100%;
  display: flex;
  align-items: center;
}

.review_image {
  width: 150px;
  height: 120px;
}

.review_image img {
  max-width: 100%;
  max-height: 100%;
}

.review_title {
  width: calc(100% - 150px);
  padding-left: 30px;
}

.review_title h2 {
  font-size: 25px;
  font-weight: 500;
  color: #012965;
  font-family: var(--Poppins);
}

.review_rating_section {
  width: 100%;
  padding: 15px 0;
}

.tooltipStyle {
  background-color: #012965;
  font-size: 14px;
  font-weight: 400;
  font-family: var(--robotoC);
}

.rating_label {
  color: #012965;
  font-family: var(--Poppins);
  font-size: 14px;
  margin-bottom: 10px;
}

.remain_counter {
  color: #012965;
  font-family: var(--Poppins);
  font-size: 14px;
  text-align: right;
  padding: 10px 0;
  text-decoration: underline;
  width: 100%;
}

.review_comment_section {
  width: 100%;
  padding: 15px 0;
}

.comment_box {
  width: 100%;
  height: 150px;
  padding: 15px;
  border: 1px solid rgb(255 0 0 / 20%);
  border-radius: 2px;
  resize: none;
  font-family: var(--Poppins);
}

.comment_box:focus-visible {
  outline: none;
}

.review_btn_section {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.review_submit_btn {
  border: none;
  border-radius: 2px;
  color: #fff;
  background-color: #012965;
  padding: 5px 15px;
  cursor: pointer;
  font-family: var(--Poppins);
}

.mini_item_container {
  height: 100%;
  width: 120px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.mini_image {
  width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .productPrevPopup {
    width: 100%;
  }

  .closeCart {
    top: 10px;
    right: 10px;
    font-size: 30px;
    z-index: 1;
    width: 20px;
    height: 20px;
  }

  .prev_title h2 {
    font-weight: 500;
  }

  .previewDivider {
    display: block;
    overflow-y: auto;
    padding: 12px;
  }


  .item_description {
    display: flow-root;
  }

  .itemImage {
    width: 100%;
    height: 220px;
    border-right: none;
    padding: 0;
  }

  .itemDetails {
    width: 100%;
    padding-left: 0;
    height: auto;
    padding: 0;
  }

  div.prev_price {
    padding: 15px 0;
  }

  .prev_add {
    padding: 20px 0 30px;
  }

  .bottomSection {
    min-height: 20px;
  }

  .ukbdTabDivider {
    width: 100%;
  }

  .ukbdTabDivider:first-child {
    padding-right: 0px;
  }

  .ukbdTabDivider:last-child {
    border: none;
    margin-top: 15px;
    width: 100%;
  }

  .tabBody {
    padding: 30px 30px;
  }

  .tabBody textarea {
    min-height: 220px;
  }

  .review_popup {
    padding: 40px 30px;
  }

  .review_title_section {
    align-items: flex-start;
  }

  .review_image {
    width: 90px;
    height: 100px;
  }

  .review_title {
    width: calc(100% - 90px);
    padding-left: 15px;
  }

  .review_title h2 {
    font-size: 18px;
  }

  .tabs {
    font-size: 14px;
  }
}