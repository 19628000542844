.product_grid {
  flex-basis: 25%;
  width: 250px;
  position: relative;
  padding: 12px;
  box-sizing: border-box;
  position: relative;
  display: flow-root;
  height: fit-content;
}

.USBD_items {
  flex-basis: 20%;
}

.product_overlay {
  position: absolute;
  width: calc(100% - 26px);
  height: calc(100% - 50px);
  top: 13px;
  left: 13px;
  background-color: rgb(1 41 101 / 10%);
  z-index: 1;
  display: none;
  cursor: pointer;
}

.product_grid.pInCart .product_overlay,
.product_grid:hover .product_overlay {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bigBasketIcon {
  font-size: 90px;
  color: rgb(255 255 255 / 45%);
}

.out_of_stock_item .product_overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.out_of_stock {
  position: absolute;
  top: 12px;
  width: auto;
  height: 26px;
  left: 12px;
  display: flex;
  border: 1px solid rgb(255 0 0 / 10%);
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #ff0000;
  padding: 0 10px;
  z-index: 1;
  background-color: #fff;
}

.findDetails {
  position: absolute;
  right: 13px;
  top: 13px;
  background-color: #fff;
  z-index: 1;
  height: 26px;
  display: none;
  border-radius: 2px;
  text-decoration: none;
}

.product_grid:hover .findDetails {
  display: flex;
}

.findDetails span {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 10px;
  background-color: transparent;
  color: #ff0000;
  width: 100%;
  height: 100%;
  font-family: var(--robotoC);
  font-size: 16px;
  font-weight: 300;
  cursor: pointer;
  border-radius: 2px;
}

.findDetails span:hover {
  background-color: #012965;
  color: #fff;
}

.product {
  width: 100%;
  height: 423px;
  background-color: #fff;
  padding-top: 25px;
  border-radius: 2px;
  border: 1px solid rgba(255, 0, 0, 0.1);
  position: relative;
}

.product:hover {
  box-shadow: 0px 4px 4px rgb(255 0 0 / 10%);
}

.product_img {
  width: 100%;
  height: 198px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 10px;
}

.productDescription {
  display: flow-root;
}

.product_img img {
  width: 100%;
  max-height: 100%;
  border-radius: 4px;
  object-fit: contain;
  object-position: center;
  transition: transform 0.2s;
}

.product_title {
  width: 100%;
  float: left;
  height: 70px;
  padding: 0 25px;
  margin-bottom: 5;
}

.product_title p {
  width: 100%;
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 600;
  text-align: left;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #012965;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-transform: capitalize;
  margin-top: 10px;
  cursor: pointer;
}

.product_title p:hover {
  color: #ff0000;
}

.product_shortDesc {
  width: 100%;
  float: left;
  height: 80px;
  padding: 0 25px;
  margin-bottom: 12px;
}

.product_shortDesc p {
  width: 100%;
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 300;
  text-align: left;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #012965;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-transform: capitalize;
  margin-top: 10px;
  cursor: pointer;
  position: relative;
}

.product_title .product_weight {
  width: 100%;
  float: left;
  height: 22px;
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #7a6d6d;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-top: 4px;
}

.productOrigin {
  width: 100%;
  padding: 0 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  float: left;
}

.originLogo {
  width: 50%;
  height: 24px;
  display: flex;
  align-items: center;
  position: relative;
}

.originLogo:first-child {
  justify-content: flex-start;
}

/* .originLogo:last-child {
  justify-content: flex-end;
} */
.originLogo img {
  max-width: 100%;
  max-height: 24px;
}

.supplierLabel {
  text-transform: capitalize;
  font-size: 9px;
  font-weight: 500;
  color: #012965;
  font-family: var(--robotoC);
  position: absolute;
  top: -10px;
  letter-spacing: 0.8px;
}

.supplier_name {
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 500;
  color: #012965;
  font-family: var(--robotoC);
  position: absolute;
  letter-spacing: 0.8px;
}

.vatTaxLabel {
  text-transform: capitalize;
  font-size: 11px;
  font-weight: 500;
  color: #012965;
  font-family: var(--robotoC);
  position: relative;
  letter-spacing: 0.8px;
  float: left;
  padding: 0 25px;
  top: -13px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

.product_price {
  width: 100%;
  height: 74px;
  float: left;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 14px 25px 14px;
  /* border-top: 1px solid rgb(255 0 0 / 10%); */
  position: relative;
  flex-direction: column;
}

.product_price::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translate(0px, -2px);
  height: 1px;
  width: calc(100% - 50px);
  background-color: rgb(1 41 101 / 20%);
}

.product_price p {
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #012965;
}

.product_price .prev_price {
  font-size: 12px;
  display: flex;
  align-items: flex-end;
  position: absolute;
  right: 25px;
  top: 17px;
}

.product_price .prev_price span:first-child {
  margin-right: 5px;
}

.product_price .prev_price span:last-child {
  text-decoration: line-through;
}

.uk_price {
  padding-bottom: 1px;
}

p.bd_price {
  padding-top: 0px;
  font-size: 14px;
}

.fractionValue {
  font-size: 12px;
}

.product_price .deliveryTime {
  font-size: 11px;
  display: flex;
  align-items: flex-end;
  position: absolute;
  right: 25px;
  top: 38px;
}

.deliveryTimeIcon {
  color: #012965;
  margin-right: 5px;
  font-size: 12px !important;
  line-height: 20px !important;
}

.product_cart {
  width: 100%;
  height: 24px;
  float: left;
  display: flex;
  align-items: center;
}

.product_cart.preview {
  width: 33.333%;
}

.product_cart button {
  width: 100%;
  height: 24px;
  border: 1px solid rgba(255, 0, 0, 0.1);
  border-right: none;
  border-bottom: none;
  font-size: 18px;
  background: #fff;
  border-radius: 2px;
  padding: 0px;
  cursor: pointer;
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #012965;
  transform: all 200ms ease;
  margin: 0;
}

.product_cart .addButton {
  background-color: #fff;
  color: #ff0000;
  border-left: none;
  border-radius: 0 0 0 2px;
  visibility: visible;
  width: calc(100% - 24px);
}

.product_grid:hover .addButton,
.product:hover .addButton {
  visibility: visible;
  background-color: #ff0000;
  color: #fff;
}

button.wishButton {
  width: 24px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 2px 0;
}

.product_cart .quantity_group {
  width: calc(100% - 24px);
  text-align: center;
  height: 24px;
  border-radius: 0 0 0 2px;
}

.product_cart .quantity_group span {
  /* font-size: 35px; */
  color: #fff;
  font-size: 14px;
}

.product_cart .quantity_group .quantity {
  cursor: pointer;
  width: calc(100% - 48px);
  float: left;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  background-color: #ff0000;
  letter-spacing: 0.02em;
  color: #fff;
  user-select: none;
}

.product_cart .quantity_group .max_btn {
  cursor: pointer;
  width: 24px;
  float: right;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff0000;
  /* border-radius: 0 2px 2px 0; */
}

.product_cart .quantity_group .min_btn {
  cursor: pointer;
  width: 24px;
  float: left;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff0000;
  border-radius: 0 0 0 2px;
}

.preview .addButton {
  visibility: visible;
  border: 1px solid rgba(255, 0, 0, 0.3);
  border-right: none;
  border-radius: 0 2px 2px 0;
  color: #ff0000;
}

.preview .addButton:hover {
  background-color: #ff0000;
  color: #fff;
}

.preview button.wishButton {
  border: 1px solid rgba(255, 0, 0, 0.3);
  /* border-left: none; */
  border-radius: 0 2px 2px 0;
  color: #ff0000;
}

.productTag {
  position: absolute;
  top: -1px;
  width: auto;
  height: 26px;
  left: -1px;
  display: flex;
  border: 1px solid rgb(255 0 0 / 10%);
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #ff0000;
  padding: 0 10px;
  float: left;
}

.productTagLeft {
  position: absolute;
  top: 0;
  width: auto;
  height: 24px;
  left: -1px;
  display: flex;
  border: 1px solid rgb(255 0 0 / 10%);
  border-top: 0;
  border-left: 0;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #ff0000;
  padding: 0 10px;
  text-transform: uppercase;
}

.basketIcon {
  margin-right: 5px;
  position: relative;
  top: -1px;
  font-size: 14px;
}

.quantity_group_update {
  float: left;
  width: 120px;
  height: 56px;
  padding: 4px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  color: #012965;
}

/* .quantity_group_update select {
  border: none;
  border-bottom: 1px solid rgb(1 41 101 / 10%);
  background: transparent;
  width: 50px;
  height: 24px;
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.02em;
  color: #012965;
}

.quantity_group_update select:focus-visible {
  outline: none;
  border-bottom: 1px solid rgb(1 41 101 / 80%);
} */

.quantity_group_update span {
  font-size: 16px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.quantity_group_update .quantity {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cartBtnGroup {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgb(1 41 101 / 20%);
  border-radius: 2px;
  height: 24px;
}

.cartBtnGroup input {
  border: none;
  text-align: center;
  width: calc(100% - 40px);
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.02em;
  color: #012965;
  background-color: transparent;
}

.cartBtnGroup input:focus-visible {
  border: none;
  outline: none;
}

/* Cart Total Price CSS */

.cartingOption {
  position: absolute;
  top: 23px;
  right: 0;
  min-width: 225px;
  background-color: #fff;
  z-index: 10;
  box-shadow: 0px 1px 3px rgb(0 0 0 / 25%), 0px 4px 28px rgb(180 180 180 / 25%);
  border-radius: 0 0 2px 2px;
  padding: 30px 25px;
}

.cartingOptionItem {
  width: 100%;
  height: 31px;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 5px;
  transition: all 250ms ease;
  border-bottom: 1px solid rgb(1 41 101 / 20%);
  border-radius: 2px;
}

.cartingOptionItem:last-child {
  border: none;
}

.cartingOptionItem p {
  justify-content: flex-start;
}

.cartingOptionItem p .pro_qty {
  left: 2px;
}

.cartingOptionItem:hover {
  background-color: rgba(1, 41, 101, 0.1);
}

p.cartBag {
  width: auto;
  justify-content: center;
}

p.cartBag .pro_qty {
  height: 15px;
  bottom: 2px !important;
  margin: 0;
  width: 20px;
}

.cartPrice {
  white-space: nowrap;
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: normal;
  letter-spacing: 0.02em;
  margin-left: 8px;
  color: #ff0000;
  height: 100%;
  align-items: flex-end !important;
  padding-bottom: 1px;
}

.pro_qty {
  position: absolute;
  color: #fff !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  left: 5px;
  bottom: 3.5px !important;
  line-height: normal;
  padding-top: 2px;
  margin: 0;
  width: 20px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.srItemAddButton {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  border: 1px solid rgb(255 0 0 / 20%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: rgb(255 0 0 / 40%);
  cursor: pointer;
}

.srItemAddButton:hover {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  /* border: 1px solid rgb(255 0 0 / 20%); */
  background-color: #ff0000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #fff;
}

/* @media (max-width: 2560px) {
  .product_grid {
    flex-basis: 20%;
  }
} */
@media (min-width: 1920px) {
  .product_grid {
    flex-basis: 20%;
  }
}

@media (max-width: 1920px) {
  .product_grid {
    flex-basis: 20%;
  }

  .USBD_items {
    flex-basis: 16.66%;
  }
}

@media (max-width: 1680px) {
  .product_grid {
    flex-basis: 20%;
  }

  .USBD_items {
    flex-basis: 16.66%;
  }
}

@media (max-width: 1440px) {
  .product_grid {
    flex-basis: 25%;
  }

  .USBD_items {
    flex-basis: 20%;
  }

}

@media (max-width: 1366px) {
  .product_grid {
    flex-basis: 25%;
  }

  .USBD_items {
    flex-basis: 20%;
  }
}

@media (max-width: 1333px) {
  .product_grid {
    flex-basis: 33.33%;
  }
}

@media (max-width: 1073px) {
  .product_grid {
    flex-basis: 33.33%;
  }
}

@media (max-width: 815px) {
  .product_grid {
    flex-basis: 50%;
  }
}

/* @media(max-width: 620px) {
  .col {
    clear: both;
    float: none;
    margin-left: auto;
    margin-right: auto;
    width: auto !important;
  }
} */

@media (max-width: 768px) {
  p.uk_price {
    font-size: 20px;
  }

  .product_grid {
    padding: 8px 0 16px;
    border-bottom: 1px solid rgba(255, 0, 0, 0.1);
  }

  .product_grid:first-child {
    margin-top: 15px;
  }

  .product {
    height: auto;
    width: 100%;
    padding: 0px 10px;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    justify-content: space-between;
    border: none;
  }

  .product:hover {
    box-shadow: none;
  }

  .product_img {
    width: 40%;
    height: 172px;
    padding: 30px 0;
    margin-bottom: 0;
  }

  .productDescription {
    width: 60%;
    padding-right: 10px;
  }

  .productTag {
    position: unset;
  }

  .product_title {
    padding: 0;
    height: auto;
    margin-bottom: 10px;
  }

  .product_shortDesc {
    padding: 0;
    height: 46px;
  }

  .product_shortDesc p {
    -webkit-line-clamp: 2;
  }

  .product_price {
    height: 60px;
    padding: 8px 0px 8px;
  }

  .product_price .prev_price {
    right: 0;
    top: 8px;
  }

  .product_price .prev_price span:last-child {
    padding-top: 3px;
  }

  .product_price::before {
    width: calc(100%);
    transform: translate(0px, 0px);
  }

  .product_cart {
    width: 100%;
  }

  .productDescription .product_cart {
    width: calc(40% - 10px);
    right: 10px;
    position: absolute;
    bottom: 0;
    padding-right: 0px;
    z-index: 1;
  }

  .product_cart.preview {
    width: 50%;
  }

  .productOrigin {
    margin: 10px 0 36px;
  }

  .productDescription .product_cart .addButton {
    visibility: visible;
    background-color: #fff;
    color: #ff0000;
  }

  .product_cart .addButton {
    border: 1px solid rgba(255, 0, 0, 0.3);
    border-right: none;
    background-color: #fff;
    color: #ff0000;
  }

  button.wishButton {
    border: 1px solid rgba(255, 0, 0, 0.3);
  }

  .product_grid .findDetails {
    display: flex;
    bottom: 16px;
    right: unset;
    top: unset;
    left: 10px;
    border: 1px solid rgb(1 41 101 / 20%);
    height: 24px;
  }

  .findDetails span {
    background-color: #fff;
    color: #012965;
  }

  .product_overlay {
    width: calc(100% - 0px);
    height: calc(100%);
    top: 0px;
    left: 0px;
  }

  .product_cart .quantity_group {
    border: 1px solid rgba(255, 0, 0, 0.3);
    border-right: none;
  }

  .product_cart .quantity_group .min_btn {
    background-color: #ff0000;
    color: #fff;
  }

  .product_cart .quantity_group .max_btn {
    background-color: #ff0000;
    color: #fff;
  }

  .product_cart .quantity_group .quantity {
    background-color: #ff0000;
    color: #fff;
  }

  .product_price .deliveryTime {
    right: 0;
    top: 31px;
  }

  .productOrigin {
    padding: 0;
  }

  .vatTaxLabel {
    top: -8px;
    padding: 0;
  }
}

@media (max-width: 555px) {
  .product_grid {
    flex-basis: 100%;
  }
}