.wrapperContainer {
    width: 100%;
    height: calc(100%);
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgb(255 255 255 / 95%);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.closePopup {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(255 0 0 / 20%);
    font-size: 35px;
    position: absolute;
    right: 18px;
    top: 15px;
    cursor: pointer;
}

.opt_container {
    width: 600px;
    padding: 60px 80px;
    background-color: #fff;
    border: 1px solid rgb(255 0 0 / 40%);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main_title {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    font-family: 'Poppins';
    margin-bottom: 20px;
    color: #012965;
}

.sub_title {
    text-align: center;
    font-size: 18px;
    font-weight: 300;
    font-family: 'Poppins';
    margin-bottom: 20px;
    color: #012965;
    line-height: 24px;
}

.message_box {
    text-align: left;
    font-size: 16px;
    font-weight: 300;
    font-family: 'Poppins';
    margin-bottom: 40px;
    color: #012965;
    line-height: 24px;
}

.opt_label {
    text-align: center;
    font-size: 16px;
    font-weight: 300;
    font-family: 'Poppins';
    color: #012965;
    line-height: 18px;
}

.opt_input_container {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    padding: 10px 0;
    margin-bottom: 30px;
    position: relative;
    width: 301px;
}

.opt_input {
    border-radius: 2px;
    border: 1px solid rgb(255 0 0 / 30%);
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 18px;
    color: #012965;
}

.opt_resend {
    text-align: end;
    font-size: 12px;
    font-weight: 200;
    font-family: 'Poppins';
    color: #fff;
    line-height: 14px;
    position: absolute;
    width: auto;
    right: 6px;
    bottom: -14px;
    border-radius: 2px;
    cursor: pointer;
}

.resendBTN {
    text-align: end;
    font-size: 12px;
    font-weight: 300;
    font-family: 'Poppins';
    color: #ff0000;
    line-height: 14px;
    width: auto;
    border: 1px solid rgb(255 0 0 / 30%);
    padding: 2px 5px;
    border-radius: 2px;
    cursor: pointer;
}

.resendBTN:hover {
    color: #fff;
    background-color: #ff0000;
}

.resendText {
    text-align: end;
    font-size: 12px;
    font-weight: 300;
    font-family: 'Poppins';
    color: #012965;
    line-height: 14px;
    width: auto;
    background-color: #fff;
    padding: 2px 5px;
    border-radius: 2px;
    cursor: pointer;
}

.opt_btn_container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.btn_container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
}

.opt_verify_btn {
    color: #ff0000;
    border: 1px solid rgb(255 0 0 / 30%);
    background-color: #fff;
    padding: 0 16px;
    font-size: 15px;
    height: 26px;
    border-radius: 2px;
    min-width: 100px;
    cursor: pointer;
}

.opt_verify_btn:hover {
    background-color: #ff0000;
    color: #fff;
}

.opt_spam_label {
    text-align: center;
    font-size: 12px;
    font-weight: 300;
    font-family: 'Poppins';
    color: #012965;
    line-height: 12px;
}

@media (max-width: 768px) {
    .wrapperContainer {
        height: calc(100% - 60px);
        position: fixed;
        left: 0;
        top: 40px;
    }

    .opt_container {
        width: 90%;
        padding: 40px 30px;
    }

    .sub_title {
        font-size: 16px;
    }

    .opt_spam_label {
        line-height: 14px;
    }

    .message_box {
        font-size: 15px;
    }
}