.popup_wraper {
    width: 100vw;
    height: calc(100% - 50px);
    position: fixed;
    top: 30px;
    right: 0;
    background-color: rgb(1 41 101 / 50%);
    z-index: 10;
}

.popupInner {
    width: 100%;
    background-color: #fff;
    /* min-height: 50%; */
    /* max-height: 100%; */
    height: 80%;
    position: relative;
    padding: 50px;
}

.deshi_wraper {
    /* background-image: url("https://ai2-ukbd.s3.us-east-2.amazonaws.com/web_images/cover_image3kYp.jpg"); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.deshiInner {
    width: 100%;
    height: 80%;
    position: relative;
    padding: 50px 290px;
    box-shadow: 0 40px 40px -1px rgb(1 41 101), 0 1px 1px -1px rgb(1 41 101 / 88%);
}

.popup_header {
    width: 100%;
    height: 60px;
}

.popup_body {
    width: 100%;
    height: calc(100% - 60px);
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    flex-wrap: wrap;
    overflow-y: auto;
}

.popup_body_content {

}

.main_title {
    color: #ff0000;
    font-size: 35px;
    font-family: var(--Poppins);
    font-weight: 100;
    text-transform: uppercase;
}

.sub_title {
    text-align: left;
    font-size: 22px;
    font-family: var(--Poppins);
    color: #012965;
    margin-bottom: 20px;
    font-weight: 200;
}

.body_text {
    text-align: left;
    font-size: 16px;
    font-family: var(--Poppins);
    color: #012965;
    margin-bottom: 20px;
    font-weight: 200;
    line-height: 22px;
}

.imageContainer {
    width: 100%;
    height: 250px;
    display: flex;
    margin-bottom: 20px;
    justify-content: flex-start;
}

.imageContainer img{
    max-width: 100%;
    max-height: 100%;
}

.closePopup {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 300;
    color: rgba(255, 0, 0, 0.2);
    border-radius: 2px;
    font-family: var(--robotoC);
    width: 27px;
    height: 27px;
    cursor: pointer;
    top: 25px;
    right: 42px;
    font-size: 40px;
    text-decoration: none;
    background-color: transparent;
}

.header_section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header_title {
    color: #ff0000;
    font-size: 18px;
    font-family: var(--Poppins);
    font-weight: 200;
    margin-bottom: 8px;
}

.header_search_input {
    width: 60%;
    height: 35px;
    border: 1px solid rgb(255 0 0 / 20%);
    padding: 0 15px;
    font-size: 16px;
    color: #012965;
    font-family: var(--Poppins);
    border-radius: 2px;
    font-weight: 200;
}

.header_search_input::placeholder {
    font-weight: 100;
}

.header_search_input:focus-visible {
    outline: none;
}

.body_section {
    width: 100%;
    height: calc(100% - 92px);
    display: flex;
    overflow-y: auto;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 30px;
    column-gap: 15px;
}
.search_body_section {
    width: 100%;
    height: calc(100% - 92px);
    margin-top: 30px;
    column-gap: 15px;
}
.search_result_container {
    width: 60%;
    height: 100%;
    overflow-y: auto;
    margin: 0 auto;
}

.child_element_group {
    width: calc(14.28% - 13px);
}

.child_element {
    font-family: var(--Poppins);
    color: #012965;
    font-size: 13px;
    font-weight: 500;
    height: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0 10px;
    text-transform: uppercase;
    /* border-bottom: 1px solid rgb(1 41 101 / 20%); */
}

/* .grand_child_group {
    padding-left: 15px;
} */

.grand_child {
    font-family: var(--Poppins);
    color: #012965;
    font-size: 12px;
    font-weight: 300;
    height: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    padding: 0 10px;
    border-radius: 2px;
}

.grand_child:hover {
    /* text-decoration: underline; */
    background-color: rgba(1, 41, 101, .1);
}

.srItemHolder {
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.srItem {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 2px;
    text-decoration: none;
}

.sri_image {
    width: 40px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sri_image img {
    max-width: 100%;
    max-height: 100%;
}

.sriDetails {
    padding: 0 10px 0 6px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.itemInCart .sriDetails {
    background-color: rgb(1 41 101 / 5%);
}

.sri_name {
    width: calc(100% - 90px);
    padding-left: 5px;
}

.sri_name p {
    font-family: var(--robotoC);
    font-weight: 300;
    font-size: 15px;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #012965;
    text-transform: capitalize;
}

.sri_price {
    width: 90px;
    font-family: var(--robotoC);
    font-weight: 300;
    font-size: 15px;
    text-align: right;
    color: #012965;
}

.fractionValue {
    font-size: 12px;
}

.srItemAdd {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
}

.srItemAddButton {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    border: 1px solid rgb(255 0 0 / 20%);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: rgb(255 0 0 / 40%);
    cursor: pointer;
}

.header_search_input::placeholder {
    color: rgb(1 41 101 / 30%);
}