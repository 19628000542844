.floatOptions {
  position: absolute;
  width: 32px;
  background: #012965;
  /* box-shadow: 0px 4px 4px rgb(0 0 0 / 25%); */
  border-radius: 2px 0px 0px 2px;
  padding: 20px 5px;
  right: 0;
  bottom: 100px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  z-index: 999;
  box-shadow: -1px 0px 7px 2px rgba(0, 0, 0, 0.24);
  -webkit-box-shadow: 0px 0px 8px 1px rgb(0 0 0 / 20%);
  -moz-box-shadow: -1px 0px 7px 2px rgba(0, 0, 0, 0.24);
  transition: 0.3s ease;
}

.floatItem {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
  margin-bottom: 5px;
  transition: all 250ms ease;
}

.floatItem:hover {
  border-radius: 50%;
  background-color: #053b8d;
}

.floatItem:last-child {
  margin-bottom: 0;
}

.scrollTop {
  width: 40px;
  height: 40px;
  background: #fff;
  position: fixed;
  right: 0;
  bottom: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  cursor: pointer;
  /* -moz-box-shadow: -1px 0px 7px 2px rgba(0, 0, 0, 0.24); */
  transition: 0.3s ease;
}
.scrollIcon {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgb(1 41 101 / 10%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #012965;
}

@media (max-width: 768px) {
    .floatOptionsHide {
        bottom: 55px;
    }
    .floatOptions.floatOptionsHide {
      bottom: -60%;
    }
}
