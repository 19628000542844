.carting_popup {
  background: #fff;
  width: 100vw;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 6;
}

.carting_head {
  display: flex;
  padding: 12px;
  height: 85px;
  width: 100%;
  position: relative;
}

.pageTitleSection {
  width: auto;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.pageLogoHolder {
  width: 40px;
  height: 40px;
  border-radius: 2px;
  overflow: hidden;
  float: left;
  margin-right: 15px;
}

.pageLogo {
  width: 100%;
  height: 100%;
}

.pageTitle {
  float: left;
  font-size: 18px;
  font-family: var(--robotoC);
  font-weight: 500;
}

.bagSaveBtn {
  width: auto;
  height: 24px;
  color: #ffffff;
  cursor: pointer;
  background: #012965;
  border: none;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.12),
    0px 2px 4px -1px rgba(0, 0, 0, 0.07);
  font-family: var(--robotoC);
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  letter-spacing: 0.02em;
}

.closeCart {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  color: rgba(255, 0, 0, 0.2);
  border-radius: 2px;
  font-family: var(--robotoC);
  width: 27px;
  height: 27px;
  cursor: pointer;
  top: 25px;
  right: 42px;
  font-size: 40px;
  text-decoration: none;
  background-color: #fff;
}

.cartItemContainer {
  height: calc(100% - 185px);
  width: 100%;
  position: relative;
  padding: 0 100px;
}

.itemPart {
  width: calc(100% - 300px);
  display: flow-root;
  float: left;
  height: 100%;
}

.sectionTitle {
  width: 100%;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--robotoC);
  color: #012965;
}

.summaryPart {
  width: 300px;
  float: right;
  position: sticky;
  height: 100%;
  top: 0px;
  overflow: hidden;
}

.invoiceContainer {
  background-color: #E4EDFF;
  border-radius: 2px;
  padding: 25px;
  width: 100%;
  height: calc(100% - 24px);
  display: flow-root;
}

.summarySections {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.summaryInnerTop {
  overflow-y: auto;
  height: calc(100% - 126px);
}

.summaryInnerBottom {
  height: auto;
}

.bagSection {
  padding: 15px 0;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgb(1 41 101 / 6%);
}

.bagSection .bagdata {
  font-family: var(--robotoC);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  color: #012965;
}

.promoLabel {
  font-family: var(--robotoC);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  margin-top: 10px;
  display: block;
  color: #012965;
}

.promoCodeSection {
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-between;
}

.promoCodeSection input {
  height: 24px;
  width: calc(100% - 57px);
  border: none;
  background: transparent;
  font-size: 16px;
  font-weight: 300;
  font-family: var(--robotoC);
  color: #012965;
}

.promoCodeSection input::placeholder {
  color: #01296593;
}

.promoCodeSection input:focus-visible {
  outline: none;
}

.promoAdd {
  border: 1px solid rgba(1, 41, 101, 0.2);
  background: transparent;
  color: #012965;
  height: 24px;
  padding: 0 10px;
  border-radius: 2px;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-family: var(--robotoC);
  font-weight: 300;
}

.promoAdd:hover {
  color: #fff;
  background-color: #012965;
}

.totalSection {
  border-top: 1px solid rgb(1 41 101 / 20%);
  padding: 15px 0;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-between;
}

.totalSection .totaldata {
  font-family: var(--robotoC);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #012965;
}

.checkOutSection {
  margin-top: 10px;
}

.checkOutSection a {
  text-decoration: none;
}

.checkoutButton {
  width: 100%;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: none;
  background-color: #ff0000;
  border-radius: 2px;
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-family: var(--robotoC);
  font-weight: 300;
}

.itemImg {
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.itemImg_2 {
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.itemImg_2 img,
.itemImg img {
  max-width: 100%;
  max-height: 100%;
}

.carting_item {
  padding: 5px 12px;
  float: left;
  width: 100%;
  border-bottom: 1px solid rgb(1 41 101 / 10%);
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.carting_item:last-child {
  border-bottom: none;
}

.removeItem {
  color: #012965;
  font-size: 14px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.itemDetails {
  width: calc(100% - 410px);
  padding: 0 10px;
}

.itemDetails_2 {
  width: calc(100% - 110px);
  padding-left: 10px;
}

.carting_name {
  width: 100%;
  float: left;
  width: 100%;
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #012965;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-decoration: none;
}

.carting_name_2 {
  width: 100%;
  float: left;
  width: 100%;
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #012965;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.carting_shortDesc {
  width: 100%;
  float: left;
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: #012965;
}

.carting_size {
  width: 100%;
  float: left;
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  color: #012965;
}

.itemPrice {
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: var(--robotoC);
  font-weight: 500;
  font-size: 18px;
  color: #012965;
}

.itemPrice_2 {
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: var(--robotoC);
  font-weight: 500;
  font-size: 18px;
  color: #012965;
}

.total_in_bd {
  font-family: var(--robotoC);
  font-weight: 300;
  font-size: 16px;
  color: #012965;
}

.total_amount {
  color: #012965;
}

.addCommentBtn {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cIcon {
  font-size: 14px;
  color: #012965;
}

.clabel {
  font-size: 12px;
  color: #012965;
  font-family: var(--robotoC);
  font-weight: 300;
}

.rememberContainer {
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 300;
  display: block;
  position: relative;
  padding-left: 35px;
  color: #012965;
  margin-bottom: 12px;
  margin-top: 8px;
  cursor: pointer;
  line-height: 19px;
  height: 14px;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.rememberContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 2px;
  background-color: transparent;
  border: 1px solid rgba(255, 0, 0, 0.2);
}

/* On mouse-over, add a grey background color */
.rememberContainer:hover input~.checkmark {
  background-color: #ff0000;
}

/* When the checkbox is checked, add a blue background */
.rememberContainer input:checked~.checkmark {
  background-color: #ff0000;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.rememberContainer input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.rememberContainer .checkmark:after {
  left: 7px;
  top: 3px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.fractionValue {
  font-size: 14px;
}

.cartItemsContainer {
  height: calc(100% - 24px);
  display: flow-root;
  overflow-y: auto;
  position: relative;
  background-color: #F8FAFF;
  padding: 0 25px;
}

.cartTabContainer {
  width: 100%;
  min-height: 120px;
  display: flow-root;
}

.tabContainer {
  width: 100%;
  height: calc(100%);
  display: flow-root;
}

.tabHead {
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  bottom: -1px;
}

.tabs {
  width: 33.33%;
  font-family: var(--robotoC);
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  background-color: rgb(1 41 101 / 5%);
  border: 1px solid rgb(1 41 101 / 20%);
  border-right: none;
  border-bottom: none;
  transition: all 250ms ease;
  color: #012965;
}

.tabs:first-child {
  border-radius: 2px 0 0 0;
}

.tabs:last-child {
  border-right: 1px solid rgb(1 41 101 / 20%);
  border-radius: 0 2px 0 0;
}

.tabs.active {
  background-color: #fff;
  border-bottom: none;
  border-left: 1px solid rgb(1 41 101 / 20%);
}

.tabBody {
  width: 100%;
  height: calc(100% - 48px);
  border: 1px solid rgb(1 41 101 / 20%);
  padding: 25px 35px;
  border-radius: 0 0 2px 2px;
}

.tabBody p {
  font-family: var(--robotoC);
  font-style: normal;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  color: #012965;
  text-align: left;
}

.itemComment {
  height: 54px;
  width: 150px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.itemComment p {
  color: #012965;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  display: -webkit-box;
  max-width: 150px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.emptyBag {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
}

.carting_footer {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
}

.proceed_to_chec {
  width: auto;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: none;
  background-color: #ff0000;
  border-radius: 2px;
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-family: var(--robotoC);
  font-weight: 300;
  padding: 0 85px;
  margin-top: 25px;
}

.commentBox {
  width: calc(100% - 170px);
  height: 48px;
  display: flex;
  background: #fff;
  padding: 5px;
  border: 8px solid #e4edff;
}

.commentBoxText {
  width: calc(100% - 66px);
  resize: none;
  border: none;
  white-space: nowrap;
}

.commentBoxText:focus-visible {
  outline: none;
}

.commentBox_action {
  position: relative;
  width: 66px;
  height: 100%;
  display: flex;
  align-items: center;
}

.closeComment {
  position: absolute;
  right: 4px;
  height: 20px;
  color: #ff0000;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.saveComment {
  position: absolute;
  left: 0;
  background: #012965;
  border-radius: 2px;
  height: 20px;
  color: #fff;
  border: none;
  font-size: 14px;
  font-family: var(--robotoC);
  cursor: pointer;
}

.total_section {
  border: none;
  display: flex;
  justify-content: space-between
}

@media (max-width: 768px) {

  .closeCart {
    top: 10px;
    right: 10px;
    font-size: 30px;
    width: 20px;
    height: 20px;
  }

  .carting_head {
    width: 100%;
    height: 40px;
    justify-content: flex-start;
  }

  .cartItemContainer {
    padding: 0 12px;
    overflow-y: auto;
    height: calc(100% - 135px);
  }

  .cartItemsContainer {
    padding: 0 6px;
  }

  .carting_footer {
    height: 70px;
  }

  .bagSaveBtn {
    margin-left: 15px;
    display: none;
  }

  .itemPart {
    width: 100%;
    padding-right: 0;
    height: auto;
  }

  .carting_item {
    padding: 5px 2px;
  }

  .itemImg {
    width: 40px;
  }

  .itemDetails {
    width: calc(100% - 230px);
  }

  .carting_name {
    font-size: 14px;
    line-height: 18px;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .quantity_group {
    width: 90px;
  }

  .quantity_group_update {
    width: 90px;
  }

  .itemPrice {
    width: 70px;
  }

  .collapseLogo {
    width: 35px;
  }

  .collapseTitle {
    width: 60px;
    font-size: 14px;
  }

  .collapseSubTitle {
    width: calc(100% - 130px);
    font-size: 14px;
  }

  .collapseIcon {
    width: 35px;
  }

  .summaryPart {
    width: 100%;
    position: relative;
    margin-top: 20px;
    height: auto;
  }
}