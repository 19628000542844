.HelpFAQContainer {
    height: 100%;
    width: 100%;
    padding: 60px 200px;
    overflow-y: auto;
    display: flex;
    align-items: center;
}

.closeCart {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 300;
    color: rgba(255, 0, 0, 0.2);
    border-radius: 2px;
    font-family: var(--robotoC);
    width: 27px;
    height: 27px;
    cursor: pointer;
    top: 25px;
    right: 42px;
    font-size: 40px;
    background-color: transparent;
}

.FaqStyle h1{
  padding: 5px 0px;
  font-size: 32px;
  color: white !important;
}
.FaqStyle h2{
  padding: 3px 0px;
  font-size: 22px;
}
.FaqStyle h3{
  padding: 3px 0px;
  font-size: 18.72px;
}
.FaqStyle h4{
  padding: 2px 0px;
  font-size: 16px;
}
.FaqStyle h5{
  padding: 1px 0px;
  font-size: 13.28px;
}
.FaqStyle h6{
  padding: 1px 0px;
  font-size: 10.72px;
}

.FaqStyle p{
  font-size: 1em;
  padding: 1px 0px;
  line-height: 1.25;
}
.FaqStyle h1 span{
    color: #ff0000!important;
}
.FaqStyle h2 span{
  color:#012965 !important
}
.FaqStyle p span{
    color:#00193f !important
}




@media (max-width: 768px) {
    .HelpFAQContainer {
        padding: 25px;
    }
    .closeCart {
        right: 25px;
        background-color: #fff;
        border-radius: 2px;
    }

    
  .FaqStyle h1{
  padding: 5px 0px;
  font-size: 26px;
  color: white !important;
  }
  .FaqStyle h2{
  padding: 3px 0px;
  font-size: 18px;
  }
  .FaqStyle h3{
  padding: 3px 0px;
  font-size: 18.72px;
  }
  .FaqStyle h4{
  padding: 2px 0px;
  font-size: 16px;
  }
  .FaqStyle h5{
  padding: 1px 0px;
  font-size: 13.28px;
  }
  .FaqStyle h6{
  padding: 1px 0px;
  font-size: 10.72px;
  }

  .FaqStyle p{
  font-size: 12px;
  padding: 1px 0px;
  line-height: 1.25;
  }
  .FaqStyle h1 span{
    color: #ff0000!important;
  }
  .FaqStyle h2 span{
  color:#012965 !important
  }
  .FaqStyle p span{
    color:#00193f !important
  }
  .closeCart i{
    font-size: 28px !important;
  }
}