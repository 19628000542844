@import url("../src/assets/css/Sidebar.css");
@import url("../src/assets/css/MainContent.css");
/* @import url("../src/assets/css/Checkout.css"); */
@import url("../src/assets/fonts/stylesheet.css");

:root {
  --roboto: "Roboto";
  --robotoC: "Roboto Condensed";
  --Poppins: "Poppins";
}

* {
  box-sizing: border-box;
  font-family: var(--robotoC);
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

html {
  overflow: hidden;
  -webkit-text-size-adjust: 100%;
}

body {
  line-height: 1;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  height: 100vh;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  background: url("../src/assets/British-Flag-1.svg");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.container_middle {
  width: 100%;
  height: calc(100% - 50px);
  display: flow-root;
  position: relative;
  scroll-behavior: smooth;
  -ms-scroll-snap-type: mandatory;
  scroll-snap-type: mandatory;
  -ms-scroll-snap-points-y: repeat(100%);
  scroll-snap-points-y: repeat(100%);
  -ms-scroll-snap-type: y mandatory;
  scroll-snap-type: y mandatory;
}

.right_side_container {
  width: calc(100% - 250px);
  height: 100%;
  float: left;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(31.6501px);
  position: relative;
  z-index: 2;
  scroll-snap-stop: always;
  scroll-snap-align: start;
}

.MainContent {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  position: relative;
  padding: 0;
}

.white_background {
  background-color: #fff;
}

.loader_ico {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader_ico img {
  max-height: 100px;
}

.cartOptPopup {
  display: none;
}

.cartBoxContainer:hover .cartOptPopup {
  display: block;
}

div.singleItem_price {
  flex-direction: column;
  align-items: flex-end;
  padding: 14px 0;
}

.singleItem_price::before {
  width: 100%;
}

.singleItem_prevPrice {
  right: unset;
  left: 0;
}

.singleItem_deliveryTime {
  right: unset;
  left: 0;
}

.popularItem:hover .addButton {
  visibility: visible;
  background-color: #ff0000;
  color: #fff;
}

.MainContent::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border: 1px solid #fafafa;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border: 1px solid #fafafa;
  display: none;
}

.MainContent::-webkit-scrollbar-thumb {
  background: #d8d8d8;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #d8d8d8;
  border-radius: 10px;
}

.fa-xmark {
  color: rgba(255, 0, 0, 0.2);
}

.fa-heart,
.fa-user,
.fa-house {
  color: #012965;
}

.fa-heart:hover,
.fa-user:hover,
.fa-house:hover {
  font-weight: 600;
}

.firstSlider {
  height: calc(100% - 80px);
}

.firstSliderFH {
  height: calc(100%);
}

.secondSlider {
  height: 100px;
}

.secondSlider .slick-slide {
  width: 120px !important;
}

.slick-prev {
  left: 16px;
  z-index: 1;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: transparent;
}

.slick-next {
  right: 16px;
  z-index: 1;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: transparent;
}

.slick-prev:before,
.slick-next:before {
  font-family: "Font Awesome 6 Pro" !important;
  font-size: 18px;
  line-height: 1;
  opacity: 0.75;
  color: rgb(255 0 0 / 40%);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev:before {
  content: "\f053" !important;
}

.slick-next:before {
  content: "\f054" !important;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  background: transparent;
}

.slick-prev:hover:before,
.slick-next:hover:before {
  opacity: 1;
  color: rgb(255 0 0 / 80%);
}

.slick-prev:focus:before,
.slick-next:focus:before {
  color: rgb(255 0 0 / 40%);
}

.slick-prev:focus:hover:before,
.slick-next:focus:hover:before {
  opacity: 1;
  color: rgb(255 0 0 / 80%);
}

.slick-dots li {
  width: 20px;
  height: 20px;
}

.slick-track,
.slick-list {
  height: 100%;
  width: 100%;
}

.slick-dots {
  bottom: -65px;
}

.slick-slide div {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sub_cate_slider .slick-slide div {
  justify-content: flex-start;
}

/* .slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
} */
.slick-vertical .slick-slide {
  border: none !important;
}

._banner .slick-vertical .slick-list {
  height: 100% !important;
}

.cookieContainer {
  padding: 20px 60px;
  background-color: #FFFFFF !important;
}

.cookieContent {
  padding: 0px 0px;
  color: #012965;
  font-family: var(--robotoC);
  line-height: 22px;
  font-size: 16px;
}

.zoom-in-out-box {
  margin: 24px;
  width: 50px;
  height: 50px;
  background: transparent;
  animation: zoom-in-zoom-out 4s ease infinite;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.bannerSlider .slick-prev,
.bannerSlider .slick-next {
  top: 95%;
}

.landingSlider {
  height: calc(100vh - 50px);
  width: 50vw;
}

.landingSlider .slick-slide>div>div,
.landingSlider .slick-slide>div {
  height: 100%;
}

.landingSlider .slick-dots {
  position: absolute;
  bottom: 15px;
  right: 0;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  bottom: 0 !important;
}

.cookieBanner {
  background-color: #012965 !important;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 100000;
  padding: 15px 100px !important;
  top: unset !important;
}

.react-cookie-law-container {
  width: 100% !important;
  max-width: 100% !important;
}

.react-cookie-law-accept-all-btn {
  border: 1px solid rgb(223 227 233);
  background-color: transparent;
  color: rgb(223 227 233);
  font-weight: 300;
  font-size: 13px;
  margin: 0 5px;
  height: 25px;
  padding: 0 15px;
  cursor: pointer;
}

.react-cookie-law-accept-all-btn:hover {
  background-color: rgb(223 227 233);
  color: #012965;
}

.react-cookie-law-accept-selection-btn {
  border: 1px solid rgb(223 227 233);
  background-color: transparent;
  color: rgb(223 227 233);
  font-weight: 300;
  font-size: 13px;
  margin: 0 5px;
  height: 25px;
  padding: 0 15px;
  cursor: pointer;
}

.react-cookie-law-accept-selection-btn:hover {
  background-color: rgb(223 227 233);
  color: #012965;
}

.custom_messenger {
  position: fixed;
  right: 0px;
  z-index: 11;
  bottom: 30px;
  height: 40px;
  width: 40px;
  background-color: rgb(1 41 101 / 10%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom_messenger i {
  font-size: 30px;
  text-align: center;
  background-color: #fff;
  border-radius: 50%;
}

.sub_cate_slider .slick-prev {
  left: -30px;
}

.sub_cate_slider .slick-next {
  right: -30px;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.5, 1.5);
  }

  100% {
    transform: scale(1, 1);
  }
}

@media (max-width: 768px) {
  .MainContent {
    padding: 0px 0 0px;
  }

  .container {
    height: calc(var(--vh, 1vh) * 100);
  }

  .container_middle {
    height: calc(100% - 60px);
    background-color: #fff;
  }

  .right_side_container {
    width: calc(100%);
    background-color: #ffffff;
    padding: 0;
  }

  .fa-user,
  .fa-house {
    color: #012965;
  }

  .firstSlider {
    height: calc(100%);
  }

  .slick-dots {
    display: none !important;
  }

  .singleItem_price p.singleItem_deliveryTime {
    top: 33px;
  }

  .singleItem_price p.singleItem_prevPrice {
    top: 9px;
  }

  .cartOptPopup {
    display: block;
  }


  .cookieBanner {
    padding: 25px 25px !important;
  }

  .react-cookie-law-button-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }

  .landingSlider {
    height: 100%;
    width: 100vw;
  }

  .slick-prev {
    left: -25px;

  }

  .slick-next {
    right: -25px;
  }
}