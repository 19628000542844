.categoryItemGrid {
  flex-basis: 25%;
  /* flex: auto; */
  -ms-flex: auto;
  width: 250px;
  position: relative;
  padding: 12px;
  box-sizing: border-box;
  position: relative;
  float: left;
}

.categoryItem {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 160px;
  flex-direction: row;
  flex-wrap: wrap;
  border: 1px solid rgb(255 0 0 / 10%);
  border-radius: 2px;
  position: relative;
  margin-bottom: 27px;
}

.categoryItemIn {
  flex-basis: 5%;
  width: 100px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-radius: 2px;
  overflow: hidden;
  /* padding: 8px; */
  transition: all 300ms ease;
  border: 1px solid rgb(1 41 101 / 10%);
  border-top: none;
  border-right: none;
  position: relative;
}
.categoryItemIn:hover::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(1 41 101 / 6%);
  border-radius: 2px;
  pointer-events: none;
}
.categoryItemIn:last-child {
  border-right: 1px solid rgb(1 41 101 / 10%);
}

.categoryItemIn:hover {
  padding: 0;
}

.categoryItemIn img {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  object-position: center;
  transition: transform 0.2s;
}

.categoryTitle {
  position: absolute;
  width: 100%;
  bottom: -27px;
  left: 0;
  height: 26px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: rgb(255 255 255 / 50%); */
  color: #012965;
  font-family: var(--robotoC);
  font-size: 14px;
  letter-spacing: 0.5px;
  font-weight: 300;
  /* backdrop-filter: blur(3px); */
}


@media (max-width: 768px) {
  .categoryItemGrid {
    flex-basis: 50%;
    width: 50%;
  }

  .categoryItem {
    height: 130px;
  }
}