p {
    margin: 0;
    padding: 0;
}

.mainContainer {
    width: 100%;
    height: calc(100vh - 50px);
    font-family: 'Poppins', sans-serif;
    background: linear-gradient(rgba(255, 253, 253, 0.5) 100%, rgba(255, 255, 255, 0.5)100%), url('../map-UKBD-FINAL.png');
    /* background: linear-gradient(rgba(255, 253, 253, 0.5) 100%, rgba(255, 255, 255, 0.5)100%), url('../images/Study\ in\ the\ UK.png'); */
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    position: relative;
    scroll-snap-align: start;
    scroll-snap-stop: always;
}

.leftContainer {
    width: 50%;
    height: 100%;
    padding: 40px 60px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: rgba(255, 255, 255, .80);
    box-shadow: 1px 0px 1px rgb(1 41 101 / 10%),
        /* Right */
        0px 0px 0px rgb(1 41 101 / 10%),
        /* Left */
        0px 0px 0px rgb(1 41 101 / 10%),
        /* Bottom */
        0px 0px 0px rgb(1 41 101 / 10%);
}

.containerInner {
    width: 100%;
    height: calc(100%);
    overflow-y: auto;
    position: relative;
}

.rightContainer {
    width: 50%;
    height: 100%;
    padding: 40px 60px;
    display: flow-root;
    color: #003019;
    /* overflow-y: auto; */
    position: relative;
}

.rightContainerInner {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: nowrap;
    overflow-y: auto;
}

.logoText {
    font-family: 'Poppins';
    font-weight: 300;
    width: 100%;
    display: flex;
    height: 40px;
    cursor: pointer;
}

.ukbdtext {
    width: calc(100%);
}

.ukbdtext .title {
    color: #ff3131;
    font-weight: 100;
    font-size: 34px;
    margin: 0;
    padding: 0;
    line-height: .8;
    margin-bottom: 5px;
    text-transform: uppercase;
}

.studyBtns {
    width: 100%;
    padding-right: 30px;
    display: flex;
    align-items: center;
}

.headBtns {
    margin-right: 6px;
    padding: 0 15px;
    font-size: 11px;
    background-color: transparent;
    color: #012965;
    border: 1px solid rgb(1 41 101 / 30%);
    border-radius: 20px;
    height: 17px;
    width: auto;
    font-family: 'Poppins';
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 1px;
    cursor: pointer;
}

.headBtns.active {
    color: #fff;
    background-color: #012965;
}

.topText {
    padding: 30px 12px 20px 12px;
}

.topinnertext {
    text-align: end;
    font-family: 'Poppins', sans-serif;
    font-weight: 200;
    font-size: 16px;
    line-height: 21px;
    word-spacing: 3.5px;
    letter-spacing: 0.6px;
    color: #012965;
}

.imageBox {
    padding: 0 30px;
    margin: auto;
}

.imageBox img {
    width: 100%;
    height: 100%;
}

.bottomText {
    padding: 20px 30px 0px 30px;
}

.bottominnertext {
    text-align: end;
    font-family: 'Poppins', sans-serif;
    font-weight: 100;
    font-size: 18px;
    line-height: 26px;
    word-spacing: 3px;
    color: #012965;
}

.rightTopText {
    width: 100%;
    text-align: end;
}


.rightTopinnertext {
    font-size: 22px;
    font-weight: 200;
    text-transform: uppercase;
    word-spacing: 1.9px;
    line-height: 1;
    text-align: end;
}

.rightTopinnertext2 {
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1;
}

.formContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    margin: 10px 0 0;
    /* height: calc(100% - 75px); */
    justify-content: center;
}

.inputGroup {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 80%;
    margin-top: 3px;
    position: relative;
}

.inputGroupTerms {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
}

.termsGroup {
    width: 50%;
    text-align: left;
    padding: 5px 0;
}

.inputLabel {
    font-size: 13px;
    line-height: 19px;
    padding: 0 10px;
    position: relative;
    text-transform: uppercase;
    font-weight: 200;
}

.input {
    height: 22px;
    width: 62%;
    border-radius: 0px;
    border: 1px solid rgb(255 0 0 / 20%);
    font-size: 14px;
    padding: 0 10px;
    text-align: end;
    margin-bottom: 2px;
    color: #012965;
    font-family: 'Poppins';
    font-weight: 200;
    background-color: #fff;
}

.input:focus-visible {
    outline: none;
}

.inputFull {
    width: 100%;
}

.logoNumber {
    position: absolute;
    top: 20px;
    left: 5px;
    display: flex;
    height: 20px;
    align-items: center;
    column-gap: 5px;
}

.logoNumber p {
    font-size: 14px;
    color: #012965;
    font-family: 'Poppins';
    font-weight: 200;
}

.logoNumber img {
    width: 24px;
    height: 14px;
}

.dropDown {
    float: right;
    height: 22px;
    width: 100%;
    border-radius: 0px;
    border: solid 1px rgb(255 0 0 / 20%);
    padding: 0 10px;
    text-align: end;
    margin-bottom: 1px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #fff;
    font-size: 14px;
    color: #012965;
    font-family: 'Poppins';
    font-weight: 200;
}

.university_dropDown {
    float: right;
    height: auto;
    width: 100%;
    border-radius: 0px;
    border: solid 1px rgb(255 0 0 / 20%);
    padding: 0 10px;
    text-align: end;
    margin-bottom: 1px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #fff;
    font-size: 14px;
    color: #012965;
    font-family: 'Poppins';
    font-weight: 200;
}

.dropDownMiddle {
    float: right;
    height: 22px;
    width: 62%;
    border-radius: 0px;
    border: solid 1px rgb(255 0 0 / 20%);
    padding: 0 10px;
    text-align: end;
    margin-bottom: 1px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    top: 0;
    background-color: #fff;
    font-size: 14px;
    color: #012965;
    font-family: 'Poppins';
    font-weight: 200;
}

.submitBtn {
    width: 100%;
    text-align: end;
}

.submitBtn button {
    height: 22px;
    width: 50%;
    background-color: #ff0000;
    color: #fff;
    border: none;
    cursor: pointer;
}

.submitBtn button:hover {
    opacity: 0.6;
}

.input_label {
    font-size: 15px;
    font-weight: 300;
    padding: 0 10px;
    position: relative;
    top: 3px;
}

.rightBottomText {
    width: 100%;
    text-align: end;
    padding-top: 10px;
}

.rightBottominnertext {
    font-size: 12px;
    line-height: 14px;
    text-align: justify;
}

.documents_popup {
    width: 100vw;
    height: calc(100%);
    position: fixed;
    top: 0;
    right: 0;
    background-color: #ffffff;
    z-index: 8;
    padding: 22px 50px 50px;
}

.closeCart {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 300;
    color: rgba(255, 0, 0, 0.2);
    border-radius: 2px;
    font-family: var(--robotoC);
    width: 27px;
    height: 27px;
    cursor: pointer;
    top: 25px;
    right: 42px;
    font-size: 40px;
    text-decoration: none;
    background-color: #fff;
}

.doc_header {
    width: 100%;
    height: 50px;
}

.doc_header_title {
    font-size: 25px;
    font-weight: 500;
    color: #012965;
}

.doc_body {
    width: 100%;
    height: calc(100% - 100px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    column-gap: 20px;
    flex-direction: column;
}

.doc_info_label {
    margin-bottom: 30px;
    color: #012965;
    font-size: 14px;
    font-weight: 200;
}

.doc_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
}

.uploader {
    width: 170px;
    height: 165px;
    border: 1px solid rgb(255 0 0 / 20%);
    border-radius: 2px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #012965;
}

.uploader_label {
    text-align: center;
    font-size: 14px;
    font-weight: 200;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    /* line-break: anywhere; */
    padding: 10px;
    word-break: break-word;
    /* display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; */
    overflow: hidden;
}

.doc_footer {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 15px;
}

.doc_btn {
    color: #012965;
    padding: 0 12;
    width: 100px;
    font-size: 14px;
    border: 1px solid rgb(1 41 101 / 50%);
    background-color: #fff;
    height: 30px;
}

.doc_btn:hover {
    color: #fff;
    background-color: #012965;
}

.tag_container {
    display: flex;
    position: absolute;
    right: 54px;
    top: 17px;
}

.line1_and_line2_container {
    display: none;
}

.uploaded_doc {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.uploaded_doc img {
    max-width: 100%;
    max-height: 100%;
}

.requiredLabel {
    font-size: 10px;
    color: red;
    padding-right: 5px;
    font-weight: 400;
    letter-spacing: 0.8px;
}

.error_label {
    font-size: 10px;
    color: red;
    padding-right: 5px;
    font-weight: 400;
    letter-spacing: 0.8px;
    text-transform: uppercase;
}

.error_input {
    background-color: rgb(255 0 0 /2%);
    border-color: rgb(255 0 0 / 100%);
}

.info_section {
    align-items: flex-start;
}

.info_section .inputGroupTerms {
    justify-content: flex-start;
}

.info_section .submitBtn {
    text-align: start;
}

.info_container {
    margin-bottom: 20px;
}

.info_item {
    color: #012965;
    margin-bottom: 10px;
    font-weight: 300;
    font-size: 14px;
}

.documents_container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    column-gap: 10px;
    margin: 6px 0 8p;
}

@media (max-width: 768px) {
    .mainContainer {
        display: block;
        overflow-y: auto;
        height: calc(100vh - 60px);
    }

    .leftContainer {
        width: 100%;
        padding: 30px;
    }

    .rightContainer {
        padding: 30px;
        padding-top: 50px;
        width: 100%;
    }

    .line1_and_line2_container {
        width: 50px;
        height: 50px;
        position: absolute;
        bottom: 40px;
        left: calc(50% - 25px);
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: rgb(0 1 10 / 4%);
        z-index: 9;
        border: 1px solid rgb(0 1 10 / 10%);
    }

    .line1_vertical {
        width: 1px;
        height: 18px;
        border-right: 1px solid rgba(255, 0, 0, 0.6);
        transform: translate(-6px, 0px) rotate(130deg);
    }

    .line2_vertical {
        width: 1px;
        height: 18px;
        border-right: 1px solid rgba(255, 0, 0, 0.6);
        transform: translate(6px, 0px) rotate(50deg);
    }

    .line_cursor_box {
        width: 50px;
        height: 50px;
        cursor: pointer;
        position: absolute
    }

    .rightTopinnertext {
        font-size: 16px;
    }

    .topText {
        padding: 30px 0 20px;
    }

    .imageBox {
        padding: 0;
    }

    .bottomText {
        padding: 20px 0px 0px;
    }

    .inputGroup {
        width: 100%;
    }

    .input {
        width: 100%;
    }

    .dropDownMiddle {
        width: 100%;
    }

    .termsGroup {
        width: 100%;
    }

    .submitBtn button {
        width: 100%;
    }

    .documents_popup {
        height: calc(100% - 60px);
        top: 40px;
        padding: 21px 30px 30px;
    }

    .doc_header_title {
        font-size: 18px;
    }

    .closeCart {
        width: 20px;
        height: 20px;
        right: 33px;
        font-size: 30px;
    }

    .uploader {
        width: 100%;
        height: 65px;
    }
}