.mapContainer {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
}

.mapMainPopup {
  position: absolute;
  top: 0;
  z-index: 9;
  width: 100%;
  left: 50%;
  transform: translate(-50%);
}

.pageTitleSection {
  width: 190px;
  height: 59px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #012965;
  z-index: 99;
  padding: 10px;
}

.mapHeaderRight {
  width: 100%;
}

.pageLogoHolder {
  width: 40px;
  height: 40px;
  border-radius: 2px;
  overflow: hidden;
  float: left;
  margin-right: 15px;
}

.pageLogo {
  width: 100%;
  height: 100%;
}

.pageTitle {
  float: left;
  font-size: 18px;
  font-family: var(--robotoC);
  font-weight: 500;
}

.closeCart {
  font-weight: 300;
  border-radius: 2px;
  font-family: var(--robotoC);
  width: 20px;
  height: 20px;
  display: flex;
  font-size: 30px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 25px;
  z-index: 99;
}

.closeCart i {
  color: rgba(255, 0, 0, 0.2);
  transition: 300ms ease-in-out;
}

.closeCart i:hover {
  color: rgba(255, 0, 0, 1);
}

.orderDetails {
  display: flex;
  align-items: center;
  width: 100%;
  height: 36px;
  background-color: #ffffff;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 0 150px 0 27px;
  position: relative;
}

.orderDetails p {
  font-family: var(--robotoC);
  font-size: 16px;
  font-weight: 300;
  color: #012965;
}

.map_header {
  display: flex;
  justify-content: space-between;
}

.orderState {
  display: flex;
  align-items: center;
  width: 100%;
  height: 24px;
  background-color: transparent;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.orderState p {
  width: calc(14.28%);
  height: 24px;
  font-family: var(--robotoC);
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #ffffff;
  float: left;
  background-color: #012965;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  border-right: 1px solid #012965;
  text-transform: uppercase;
}

.orderState p.inactive {
  color: rgb(1 41 101 / 20%);
  background-color: #ffffff;
  cursor: context-menu;
  font-weight: 400;
}

.orderState p:last-child {
  border-right: none;
}

.orderState p.inactive::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(1 41 101 / 10%);
  /* backdrop-filter: blur(0.9px); */
}

.orderStateTimeline {
  /* display: flex; */
  align-items: center;
  width: 138px;
  height: auto;
  flex-wrap: nowrap;
  justify-content: space-between;
  position: absolute;
  right: 0;
  top: 36px;
  /* background-color: rgb(1 41 101 / 50%); */
  z-index: 1;
  display: none;
}

.timeline {
  margin: 23px auto;
  position: relative;
  left: 111px;
  width: 16px;
  margin-left: 10px;
}

.timeline_item {
  color: #ffffff;
  font-family: var(--font-family-2);
  text-align: center;
  font-size: 12px;
  padding: 40px 0 0 0;
  border-left: 1px solid rgb(1 41 101 / 30%);
  border-bottom: 0px solid #ffffff;
  position: relative;
  list-style-type: none;
  --item-width: calc(100%);
}

.timeline_item::after {
  content: attr(data-date);
  font-family: var(--robotoC);
  position: absolute;
  right: calc(var(--item-width) + 20px);
  top: -8px;
  float: right;
  font-weight: bold;
  white-space: nowrap;
  font-weight: 600;
  color: #012965;
  text-transform: uppercase;
}

.timeline_item.inactive::after {
  font-weight: 300;
  color: rgb(1 41 101 / 50%);
}

.timeline_item::before {
  content: "";
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: #ff0000;
  position: absolute;
  left: -5px;
  top: -6px;
}

.timeline_item.inactive::before {
  background-color: rgb(1 41 101 / 30%);
}

.timeline_item:last-child {
  border-bottom: none;
  padding: 0;
}

.orderItems_popup {
  width: 300px;
  border-radius: 2px;
  max-height: 350px;
  position: absolute;
  right: 34px;
  z-index: 9;
  background-color: #fff;
  display: flow-root;
  padding: 14px;
  border: 1px solid rgb(1 41 101 / 5%);
}

.orderItems_LIST {
  overflow-y: auto;
  max-width: calc(320px - 30px);
  display: block;
}

.addToBasket {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  font-family: var(--robotoC);
  font-size: 14px;
  font-weight: 500;
  background-color: #012965;
  color: #ffffff;
  cursor: pointer;
}

/* .home_button {
  position: absolute;
  left: 17%;
} */

.items_button {
  cursor: pointer;
}

/* @media only screen and (max-width: 800px) {
  .timeline {
    margin: 0 auto;
    position: relative;
    left: 85px;
  }
  .timeline .timeline-item {
    font-size: 1.1rem;
  }
  .timeline .timeline-item::after {
    right: calc(var(--item-width) + 30px);
  }
}
@media only screen and (max-width: 800px) {
  .timeline-item {
    padding: 1rem 1.5rem 1rem 0.5rem;
  }
  .timeline-item::after {
    right: calc(var(--item-width) + 20px);
  }
} */

/* .mapContainerStyle  {
    width: 100%;
    height: calc(100% - 60px);
    margin-top: 60px;
} */

.viewDetails_popup {
  width: 90%;
  height: 75%;
  background-color: #fff;
  display: flow-root;
  position: fixed;
  bottom: 0;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -45%);
  top: 53%;
  border-radius: 2px;
}

.viewDetails_header {
  width: 100%;
  height: 36px;
  border-bottom: 1px solid rgb(1 41 101 / 15%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
}

.viewDetails_Body {
  width: 100%;
  height: calc(100% - 36px);
  display: flex;
  justify-content: space-between;
}

.viewDetails_item_section {
  width: 70%;
  height: 100%;
  overflow-y: auto;
}

.viewDetails_summary_section {
  width: 30%;
  height: 100%;
  background-color: #E4EDFF;
  border-radius: 2px;
  padding: 15px 25px 25px;
  display: flow-root;
}

.viewDetails_header_left {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 5px;
}

.viewDetails_header_right {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  padding-right: 50px;
}

.print_download {
  cursor: pointer;
  padding: 0 10px;
  color: #fff;
  background-color: #012965;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  font-size: 14px;
  font-family: var(--robotoC);
}

.order_id {
  font-size: 16px;
  font-family: var(--robotoC);
}

.order_time {
  font-size: 16px;
  font-family: var(--robotoC);
}

.order_price {
  font-size: 16px;
  font-family: var(--robotoC);
}


.summarySections {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.summaryInnerTop {
  overflow-y: auto;
  height: calc(100% - 126px);
}

.bagSection {
  padding: 15px 0;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgb(1 41 101 / 6%);
}

.bagSection .bagdata {
  font-family: var(--robotoC);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  color: #012965;
}

.itemPrice {
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: var(--robotoC);
  font-weight: 500;
  font-size: 18px;
  color: #012965;
}

.summaryInnerBottom {
  height: auto;
}

.totalSection {
  border-top: 1px solid rgb(1 41 101 / 20%);
  padding: 15px 0;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-between;
}

.totalSection .totaldata {
  font-family: var(--robotoC);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #012965;
}

.fractionValue {
  font-size: 14px;
}

/* Map Header CSS */

.mainHeader {
  background-color: transparent;
  box-sizing: border-box;
  width: 100%;
  height: 24px;
  top: 0;
  right: 0;
  z-index: 5;
}

.head_container {
  border: 1px solid rgb(1 41 101 / 5%);
  box-shadow: 0 1px 1px -1px rgb(1 41 101 / 12%), 0 1px 1px -1px rgb(1 41 101 / 7%);
  padding: 0 25px;
  width: 100%;
  height: 100%;
  display: block;
  border-top: none;
  background: #fff;
}

.head_left {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  float: left;
}

.home_button {
  width: 32px;
  height: 24px;
  float: left;
  display: flex;
  align-items: center;
  cursor: pointer;
  /* margin-right: 16px; */
}

.head_right {
  width: 40%;
  height: 100%;
  float: left;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}

.menu_section {
  width: auto;
  height: 100%;
  float: left;
  display: flex;
  align-items: center;
  position: relative;
}

.trackingPop {
  display: none;
}

.trackingSection:hover .trackingPop {
  display: block;
}

.menu_title {
  width: auto;
  height: 20px;
  white-space: nowrap;
  background: #ffffff;
  /* box-shadow: 0px 4px 28px rgb(180 180 180 / 25%); */
  /* border: 1px solid rgba(1, 41, 101, 0.2); */
  border-radius: 2px;
  float: right;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  margin-left: 35px;
}

.menu_title i {
  font-size: 14px;
}

.menu_title span {
  text-align: center;
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.05em;
  background-color: transparent;
  color: #012965;
  width: 100%;
  height: 20px;
  border-radius: 2px;
  padding: 0px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 250ms ease;
}

.menu_title:hover span {
  /* background-color: #012965; */
  /* color: #ffffff; */
}

.menu_user {
  width: auto;
  height: 24px;
  white-space: nowrap;
  background: transparent;
  border-radius: 2px;
  float: right;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.menu_user span {
  text-align: center;
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.02em;
  background-color: transparent;
  color: #012965;
  width: 100%;
  height: 24px;
  border-radius: 2px;
  padding: 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 250ms ease;
}

@media (max-width: 768px) {
  .orderStateTimeline {
    display: block;
    top: unset;
    bottom: 22px;
  }

  .orderState {
    display: none;
  }

  .orderDetails {
    padding: 0 15px;
  }

  .order_id {
    display: none;
  }

  .order_time {}

  .order_price {}

  .orderDetails p {
    font-size: 13px;
  }

  .closeCart {
    top: 43px;
    right: 12px;
  }
}

.info_container {
  padding: 10px;
  padding-right: 22px;
  padding-bottom: 22px;
}