.popularItemGrid {
    flex-basis: 14.28%;
    /* flex: auto; */
    -ms-flex: auto;
    width: 250px;
    position: relative;
    padding: 12px;
    box-sizing: border-box;
    position: relative;
    float: left;
}

.popularItem {
    /* display: flex;
    align-items: center;
    justify-content: center; */
    display: flow-root;
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: #FFF;
    border-radius: 2px;
    position: relative;
    border: 1px solid rgb(255 0 0 / 10%);
    padding-top: 24px;
}

.overlay {
    position: absolute;
    width: 100%;
    height: calc(100% - 24px);
    top: 0px;
    left: 0px;
    background-color: rgb(1 41 101 / 10%);
    z-index: 1;
    display: none;
    cursor: pointer;
}

.popularItem.pInCart .overlay,
.popularItem:hover .overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.popularItem:hover .overlayButton .addButton {
    visibility: visible;
    background-color: #ff0000;
    color: #fff;
}

.bigBasketIcon {
    font-size: 70px;
    color: rgb(255 255 255 / 45%);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.overlayButton {
    height: 24px;
    width: 100%;
    display: flex;
}

.itemImg {
    width: 100%;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;
    margin-bottom: 10px;
}

.itemImg img {
    width: 100%;
    max-height: 100%;
    border-radius: 4px;
    object-fit: cover;
    object-position: center;
    transition: transform 0.2s;
}

.itemDetails {
    width: 100%;
    float: left;
}

.itemTitle {
    width: 100%;
    font-family: var(--robotoC);
    font-style: normal;
    font-weight: 300;
    text-align: left;
    font-size: 12px;
    height: 40px;
    line-height: 13px;
    letter-spacing: 0.6px;
    color: #012965;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-transform: capitalize;
    margin-bottom: 10px;
    padding: 0 6px;
    cursor: pointer;
}

.productTag {
    background-color: rgb(255 255 255 / 50%);
    position: absolute;
    top: 0;
    width: auto;
    height: 24px;
    left: 0;
    display: flex;
    border: 1px solid rgb(255 0 0 / 10%);
    border-left: none;
    border-top: none;
    align-items: center;
    border-radius: 2px;
    justify-content: center;
    color: #ff0000;
    padding: 0 10px;
    float: left;
    font-size: 12px;
    font-family: var(--robotoC);
    font-weight: 300;
    backdrop-filter: blur(3px);
}

.findDetails {
    background-color: #FFF;
    position: absolute;
    z-index: 2;
    top: 0;
    width: auto;
    height: 24px;
    right: 0;
    border: 1px solid rgb(255 0 0 / 10%);
    border-right: none;
    border-top: none;
    border-radius: 2px;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #ff0000;
    padding: 0 6px;
    font-size: 12px;
    font-family: var(--robotoC);
    font-weight: 300;
    text-decoration: none;
    display: none;
    letter-spacing: 0.5px;
}

.popularItem:hover .findDetails {
    display: flex;
}

.findDetails:hover {
    background-color: #012965;
    color: #FFF;
}

.priceSection {
    width: 100%;
    padding: 0 6px;
    margin-bottom: 10px;
}

.activePrice {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 15px;
}

.activePrice:first-child {
    border-bottom: 1px solid rgb(1 41 101 / 10%);
}

.first_price {
    font-family: var(--robotoC);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: #012965;
    white-space: nowrap;
}

.second_price {
    font-family: var(--robotoC);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: #012965;
    white-space: nowrap;
}

.fractionValue {
    font-size: 10px;
}

.deliveryTime {
    font-family: var(--robotoC);
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: #012965;
    white-space: nowrap;
}

.deliveryTime_org {
    font-family: var(--robotoC);
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: #012965;
    white-space: nowrap;
    padding: 0 6px;
}

.prev_price {
    font-family: var(--robotoC);
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: #012965;
    white-space: nowrap;
}

.prev_price span {
    text-decoration: line-through;
}

@media (max-width: 768px) {
    .popularItemGrid {
        width: 50%;
        flex-basis: 50%;
    }
}