.application_header {
    width: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    border-bottom: 1px solid rgb(1 41 101 / 10%);
    cursor: pointer;
    font-family: var(--Poppins);
    font-size: 14px;
}

.serial {
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #012965;
}

.application_title {
    width: calc(100% - 260px);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 10px;
    color: #012965;
}

.application_date {
    width: 210px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #012965;
}

.profileOrdersCont {
    height: calc(100%);
    overflow-y: auto;
    width: 100%;
    color: #012965;
}

.application_item {
    width: 100%;
    display: flow-root;
    height: auto;
    border-bottom: 1px solid rgb(1 41 101 / 10%);
    cursor: pointer;
    font-family: var(--Poppins);
    font-size: 14px;
}

.application_columns {
    width: 100%;
    height: 45px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
}

.application_documents {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 15px;
    padding: 0 16px;
}

.docs_item {
    width: 45px;
    height: 45px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgb(255 0 0 / 20%);
    padding: 5px;
}

.docs_item img {
    max-width: 100%;
    max-height: 100%;
}

._not_found {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #012965;
    font-size: 18px;
}
