.TermsConditionsContainer {
    height: 100%;
    width: 100%;
    padding: 60px 200px;
    overflow-y: auto;
}

.closeCart {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 300;
    color: rgba(255, 0, 0, 0.2);
    border-radius: 2px;
    font-family: var(--robotoC);
    width: 27px;
    height: 27px;
    cursor: pointer;
    top: 25px;
    right: 42px;
    font-size: 40px;
    background-color: transparent;
}



  .TermsConditions h1{
    padding: 5px 0px;
    font-size: 26px;
    color: #012965 !important;
  }
  .TermsConditions h2{
    padding: 3px 0px;
    font-size: 22px;
  }
  .TermsConditions h3{
    padding: 3px 0px;
    font-size: 18px;
  }
  .TermsConditions h4{
    padding: 2px 0px;
    font-size: 16px;
  }
  .TermsConditions h5{
    padding: 1px 0px;
    font-size: 13.28px;
  }
  .TermsConditions h6{
    padding: 1px 0px;
    font-size: 10.72px;
  }

  .TermsConditions p{
    font-size: 1em;
    padding: 1px 0px;
    line-height: 1.25;
  }
  .TermsConditions h1 span{
      color: #ff0000!important;
  }
  .TermsConditions h2 span{
    color:#012965 !important
  }
  .TermsConditions p{
      color:#00193f !important
  }
  .TermsConditions{
      color:#00193f !important
  }

@media (max-width: 768px) {
    .TermsConditionsContainer {
        padding: 25px;
    }
    .closeCart {
        right: 25px;
        background-color: #fff;
        border-radius: 2px;
    }
    .TermsConditions h1{
      padding: 5px 0px;
      font-size: 22px;
      color: #012965 !important;
      }
      .TermsConditions h2{
      padding: 3px 0px;
      font-size: 16px;
      }
      .TermsConditions h3{
      padding: 3px 0px;
      font-size: 16px;
      }
      .TermsConditions h4{
      padding: 2px 0px;
      font-size: 16px;
      }
      .TermsConditions h5{
      padding: 1px 0px;
      font-size: 13.28px;
      }
      .TermsConditions h6{
      padding: 1px 0px;
      font-size: 10.72px;
      }

      .TermsConditions p{
      font-size: 14px;
      padding: 1px 0px;
      line-height: 1.25;
      }
      .TermsConditions h1 span{
        color: #ff0000!important;
      }
      .TermsConditions h2 span{
      color:#012965 !important
      }
      .TermsConditions p{
          color:#00193f !important
      }
      .TermsConditions{
          color:#00193f !important
      }
    .closeCart i{
        font-size: 28px !important;
    }
}