.documents_popup {
    width: 100vw;
    height: calc(100%);
    position: fixed;
    top: 0;
    right: 0;
    background-color: #ffffff;
    z-index: 8;
    padding: 22px 50px 50px;
}

.closeCart {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 300;
    color: rgba(255, 0, 0, 0.2);
    border-radius: 2px;
    font-family: var(--robotoC);
    width: 27px;
    height: 27px;
    cursor: pointer;
    top: 25px;
    right: 42px;
    font-size: 40px;
    text-decoration: none;
    background-color: #fff;
}

.doc_header {
    width: 100%;
    height: 50px;
}

.doc_header_title {
    font-size: 25px;
    font-weight: 500;
    color: #012965;
}

.doc_body {
    width: 100%;
    height: calc(100% - 100px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    column-gap: 20px;
    flex-direction: column;
}

.doc_info_label {
    margin-bottom: 30px;
    color: #012965;
    font-size: 18px;
    font-weight: 300;
}

.doc_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
    position: relative;
}

.loaderContain {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
}

.uploader {
    width: 170px;
    height: 165px;
    border: 1px solid rgb(255 0 0 / 20%);
    border-radius: 2px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #012965;
}

.uploader_label {
    text-align: center;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    /* line-break: anywhere; */
    padding: 10px;
    word-break: break-word;
    /* display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; */
    overflow: hidden;
}

.uploader_label img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 2px;
}

.doc_footer {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 15px;
}

.doc_btn {
    color: #ffffff;
    padding: 0 12px;
    width: 120px;
    font-size: 13px;
    background-color: #ff0000;
    height: 22px;
    cursor: pointer;
    text-transform: uppercase;
    border: none;
}

.doc_btn:hover {
    color: #fff;
    background-color: #ff0000;
}

@media (max-width: 768px) {

    .documents_popup {
        height: calc(100% - 60px);
        top: 40px;
        padding: 20px;
    }

    .closeCart {
        width: 20px;
        height: 20px;
        top: 20px;
        right: 20px;
        font-size: 30px;
    }

    .doc_header_title {
        font-size: 20px;
    }

    .doc_container {
        flex-direction: column;
        row-gap: 20px;
    }

    .uploader {
        width: 100%;
        height: 60px;
    }
}