.mainHeader {
  background-color: transparent;
  box-sizing: border-box;
  width: 100%;
  height: 30px;
  /* position: fixed;
  top: 0;
  right: 0;
  z-index: 5; */
}

.head_container {
  border-bottom: 1px solid rgb(1 41 101 / 5%);
  box-shadow: 0 1px 1px -1px rgb(1 41 101 / 12%), 0 1px 1px -1px rgb(1 41 101 / 7%);
  padding: 0 25px 0 0;
  width: calc(100%);
  /* margin: 0 42px; */
  height: 100%;
  display: block;
  border-top: none;
  background: #fff;
}

.head_left {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  float: left;
}

.header_logo {
  width: 30px;
  height: 29px;
  box-shadow: 1px 0px 1px rgb(1 41 101 / 10%),
    /* Right */
    -1px 0px 1px rgb(1 41 101 / 10%),
    /* Left */
    0px 1px 1px rgb(1 41 101 / 10%),
    /* Bottom */
    0px -1px 1px rgb(1 41 101 / 10%);
}

.home_button {
  width: 32px;
  height: 100%;
  float: left;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 30px;
}

.icon_style {
  width: 20px;
  height: 18px;
  background: transparent;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon_style .fa-house {
  color: #ff0000;
}

.icon_style:hover .fa-house {
  font-weight: 600;
}

.search_section {
  width: 24px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.searchSuggestionPopup input {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border: none;
  border-radius: 2px;
  font-size: 18px;
  color: #012965;
  letter-spacing: 0.6px;
  font-style: normal;
  font-weight: 300;
  padding: 2px 45px 2px 38px;
}

.searchSuggestionPopup input::placeholder {
  color: rgba(1, 41, 101, 0.5);
  font-weight: 300;
}

.searchSuggestionPopup input:focus-visible {
  /* border: 1px solid #012965 !important; */
  outline: none;
}

.searchButton {
  width: 24px;
  height: 18px;
  /* background: #012965; */
  border-radius: 0 2px 2px 0;
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.02em;
  color: #012965;
  cursor: pointer;
  position: absolute;
  right: 0px;
}

.head_right {
  width: 40%;
  height: 100%;
  float: left;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}

.all_menu_section {
  width: calc(100% - 30px);
  height: 100%;
  float: left;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
}

.menu_section {
  width: auto;
  height: 100%;
  float: left;
  display: flex;
  align-items: center;
  position: relative;
}

.trackingPop {
  display: none;
}

.trackingSection:hover .trackingPop {
  display: block;
}

.menu_title {
  width: auto;
  height: 20px;
  white-space: nowrap;
  background: #ffffff;
  border-radius: 2px;
  float: right;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  /* margin-left: 35px; */
}

.menu_title i {
  font-size: 14px;
}

.menu_label {
  text-align: center;
  font-family: var(--Poppins);
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.05em;
  background-color: transparent;
  color: #012965;
  width: 100%;
  height: 20px;
  border-radius: 2px;
  padding: 0px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 250ms ease;
}

.menu_label:hover {
  text-decoration: underline;
}

.menu_title:hover span {
  /* background-color: #012965; */
  /* color: #ffffff; */
}

.menu_user {
  width: auto;
  height: 24px;
  white-space: nowrap;
  background: transparent;
  border-radius: 2px;
  float: right;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.menu_user span {
  text-align: center;
  font-family: var(--Poppins);
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.02em;
  background-color: transparent;
  color: #012965;
  width: 100%;
  height: 24px;
  border-radius: 2px;
  padding: 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 250ms ease;
}

.account_section {
  height: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.currency_section {
  height: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.dropdown_container {
  position: relative;
  width: auto;
  cursor: pointer;
  min-width: 80px;
}

.selected_currency {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border: none;
  font-family: var(--Poppins);
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  letter-spacing: 0.05em;
  color: #012965;
}

.currency_options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid rgb(1 41 101 / 10%);
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  z-index: 5;
}

.currency_option {
  padding: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: var(--Poppins);
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  letter-spacing: 0.05em;
  color: #012965;
}

.currency_option img {
  margin-right: 8px;
}

.checkout_box {
  width: auto;
  height: 100%;
  /* float: right; */
  /* margin-right: 20px; */
  position: relative;
  /* top: -5px; */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  transition: all 250ms ease;
}

.checkout_box p {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  float: left;
  position: relative;
  /* bottom: -5px; */
}

.checkout_box p span {
  font-family: var(--Poppins);
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  height: 19px;
  line-height: normal;
  letter-spacing: 0.02em;
  margin-left: 8px;
  color: #ff0000;
  width: auto;
}

.checkout_box p .pro_price {
  font-size: 14px;
  line-height: 20px;
}

.checkout_box p .pro_qty {
  color: #ff0000;
  font-size: 14px;
  font-weight: 300;
  margin-right: 0px;
  margin-left: 5px;
  display: inline-block;
  line-height: 20px;
  position: relative;
  top: -1px;
  font-family: var(--Poppins);
}

.cartDropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  width: 24px;
  height: 24px;
  float: left;
  position: relative;
  /* bottom: -5px; */
  color: #ff0000;
  font-size: 14px;
}

.cartingOption {
  position: absolute;
  top: 23px;
  right: 0;
  width: auto;
  background-color: #fff;
  z-index: 2;
  box-shadow: 0px 1px 3px rgb(0 0 0 / 25%), 0px 4px 28px rgb(180 180 180 / 25%);
  border-radius: 0 0 2px 2px;
  padding: 30px;
}

.cartingOptionItem {
  width: 100%;
  height: 40px;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 0px 18px; */
  transition: all 250ms ease;
  border-bottom: 1px solid rgb(1 41 101 / 20%);
  border-radius: 2px;
}

.cartingOptionItem:last-child {
  border: none;
}

.cartingOptionItem p {
  justify-content: flex-start;
}

.cartingOptionItem p .pro_qty {
  left: 2px;
}

.cartingOptionItem:hover {
  background-color: rgba(1, 41, 101, 0.1);
}

.cartPrice {
  white-space: nowrap;
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: normal;
  letter-spacing: 0.02em;
  margin-left: 8px;
  color: #ff0000;
  width: auto;
}

.userNavPopup {
  position: absolute;
  top: 23px;
  right: -25px;
  width: 190px;
  min-height: auto;
  background-color: #fff;
  box-shadow: 0px 1px 3px rgb(0 0 0 / 25%), 0px 4px 28px rgb(180 180 180 / 25%);
  border-radius: 0 0 2px 2px;
  z-index: 10;
  padding: 30px;
}

.userNavPopupItem {
  width: 100%;
  height: 31px;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 18px;
  transition: all 250ms ease;
  color: #012965;
  font-family: var(--robotoC);
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  border-bottom: 1px solid rgb(1 41 101 / 20%);
}

.userNavPopupItem:last-child {
  border: none;
}

.userNavPopupItem:hover {
  background-color: rgba(1, 41, 101, 0.1);
}

.user_img {
  width: 24px;
  height: 18px;
  padding: 0 3px;
  float: right;
  cursor: pointer;
  padding-bottom: 3px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #012965;
  margin-top: 2px;
}

/*  */

.total_amount {
  float: left;
  width: 50%;
  text-align: right;
  padding: 8px;
}

/* Styles for mobile Start */

.mobileHead {
  display: none;
}

.m_logo {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.m_logo img {
  max-height: 100%;
  max-width: 100%;
  border-radius: 2px;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25));
}

.m_search {
  width: auto;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin-left: 15px;
  /* border: 1px solid rgba(255, 0, 0, 0.2); */
  border-radius: 2px;
  color: #012965;
}

.m_store {
  width: auto;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin-left: 15px;
  /* border: 1px solid rgba(255, 0, 0, 0.2); */
  border-radius: 2px;
  color: #012965;
}

.m_driver {
  width: auto;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin-left: 15px;
  /* border: 1px solid rgba(255, 0, 0, 0.2); */
  border-radius: 2px;
  color: #012965;
}

.OrderListPopup {
  position: absolute;
  top: 30px;
  right: 0;
  background-color: #ffffff;
  box-shadow: 0px 3px 3px rgb(0 0 0 / 25%), 0px 4px 28px rgb(180 180 180 / 25%);
  border-radius: 0 0 2px 2px;
  width: 350px;
  z-index: 10;
  padding: 30px;
  max-height: 350px;
  overflow-y: auto;
}

.orderItem {
  width: 100%;
  height: 31px;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 5px;
  transition: all 250ms ease;
  border-bottom: 1px solid rgb(1 41 101 / 10%);
}

.orderItem:last-child {
  border: none;
}

.orderItem p {
  font-size: 15px;
  font-family: var(--robotoC);
  font-weight: normal;
  color: #012965;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
  cursor: pointer;
}

.orderItem:hover {
  background-color: rgba(1, 41, 101, 0.1);
}

.searchSuggestionPopup {
  width: 100vw;
  height: calc(100% - 50px);
  position: fixed;
  top: 30px;
  right: 0;
  background-color: rgb(1 41 101 / 50%);
  z-index: 10;
}

.searchSuggestionPopupInner {
  width: 100%;
  height: 80%;
  background-color: #ffffff;
  box-shadow: 0 40px 40px -1px rgb(1 41 101), 0 1px 1px -1px rgb(1 41 101 / 88%);
}

.page_logo {
  float: left;
  width: 35px;
  height: 35px;
  position: absolute;
  z-index: 1;
  left: 28px;
  top: 28px;
  cursor: pointer;
}

.page_logo img {
  width: 100%;
  height: 100%;
}

.searchInputSection {
  height: 38px;
  border-bottom: 1px solid rgba(1, 41, 101, 0.1);
  position: relative;
  margin: 0 20% 15px;
}

.searchInputSection .searchButton {
  right: unset;
  left: 0;
  top: 7px;
  left: 2px;
}

.searchItemContainer {
  overflow-y: auto;
  width: 100%;
  height: calc(100% - 158px);
  padding: 0 20%;
}

.closeSearch {
  position: absolute;
  right: 32px;
  top: 30px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  cursor: pointer;
}

.srItemHolder {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.itemInCart .sriDetails {
  background-color: rgb(1 41 101 / 5%);
}

.srItemAdd {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}

.srItem {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 2px;
  text-decoration: none;
}

.sriDetails {
  padding: 0 10px 0 6px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.srItem:hover .sriDetails {
  background-color: rgb(1 41 101 / 10%);
}

.sri_image {
  width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sri_image img {
  max-width: 100%;
  max-height: 100%;
}

.sri_name {
  width: calc(100% - 90px);
  padding-left: 5px;
}

.sri_name p {
  font-family: var(--robotoC);
  font-weight: 300;
  font-size: 15px;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #012965;
  text-transform: capitalize;
}

.sri_price {
  width: 90px;
  font-family: var(--robotoC);
  font-weight: 300;
  font-size: 15px;
  text-align: right;
  color: #012965;
}

.notFound {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--robotoC);
  font-weight: 300;
  font-size: 16px;
  color: #012965;
}

.orderListMap {
  width: 100%;
  top: 30px;
  padding: 25px 20%;
}

.orderListMap .orderDate {
  width: 38%;
  justify-content: flex-end;
}

.orderListMap .orderPrice {
  max-width: 100px;
  justify-content: flex-end;
}

.orderListHead .orderDate {
  justify-content: flex-start;
  width: 70%;
}

.orderListHead .orderPrice {
  justify-content: flex-end;
  width: 30%;
}

.fractionValue {
  font-size: 12px;
}


.carting_head {
  display: flex;
  padding: 12px;
  height: 65px;
  width: 100%;
  position: relative;
}

.pageTitleSection {
  width: auto;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.pageLogoHolder {
  width: 40px;
  height: 40px;
  border-radius: 2px;
  overflow: hidden;
  float: left;
  margin-right: 15px;
}

.pageLogo {
  width: 100%;
  height: 100%;
}

.pageTitle {
  float: left;
  font-size: 18px;
  font-family: var(--robotoC);
  font-weight: 500;
}

.closeCart {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  color: rgba(255, 0, 0, 0.2);
  border-radius: 2px;
  font-family: var(--robotoC);
  width: 27px;
  height: 27px;
  cursor: pointer;
  top: 25px;
  right: 42px;
  font-size: 40px;
  text-decoration: none;
  background-color: #fff;
}

/* Styles for mobile End */

@media only screen and (max-width: 1280px) {
  .checkout_box {
    margin-right: 10px;
  }
}

@media (max-width: 768px) {
  .mainHeader {
    padding: 0 6px;
    width: 100%;
    margin: 0;
    height: 40px;
    border-bottom: 1px solid rgb(1 41 101 / 20%);
    background-color: #fff;
  }

  .head_container {
    display: none;
  }

  .mobileHead {
    display: flow-root;
    width: 100%;
    margin: 0;
    padding: 0;
    border: none;
    box-shadow: unset;
  }

  .head_left {
    width: 100%;
  }

  .iconLabel {
    font-size: 8px;
    font-family: var(--Poppins);
  }

  .head_right {
    width: 50%;
    justify-content: space-between;
  }

  .checkout_box {
    padding: 0px;
    margin-left: 10px;
  }

  .userName {
    display: none !important;
  }

  .searchSection {
    width: 100%;
    height: calc(100% - 10px);
    display: flex;
    align-items: center;
    margin: 5px 0;
    border: 1px solid rgb(255 0 0 / 10%);
    border-radius: 2px;
  }

  .headSearchIcon {
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .searchInput {
    height: 28px;
    width: calc(100% - 60px);
    border: none;
  }

  .searchInput:focus-visible {
    outline: none;
  }

  .searchSuggestionPopup {
    height: calc(100% - 60px);
    width: 100vw;
    top: 40px;
    right: 0;
    left: 0;
  }

  .carting_head {
    height: 40px;
  }

  .searchInputSection {
    margin: 0 15px 15px;
  }

  .searchItemContainer {
    height: calc(100% - 100px);
    padding: 0 15px;
  }

  .closeCart {
    width: 20px;
    height: 20px;
    top: 6px;
    right: 7px;
    font-size: 30px;
  }

  .orderListMap {
    padding: 25px 11px;
  }

  p.orderId {
    display: none;
  }

  .orderListMap .orderDate {
    width: 60%;
    justify-content: flex-start;
  }

  .OrderListPopup {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: unset;
    position: unset;
    padding: 0;
    box-shadow: unset;
  }
}