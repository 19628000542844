.preview_container {
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9990;
    background-color: rgb(255 255 255);
    display: flow-root;
}

.preview_header {
    width: 100%;
    position: relative;
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 60px;
}

.preview_header h3 {
    color: #012965;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Poppins';
}

.preview_body {
    width: 100%;
    height: calc(100% - 60px);
    display: flow-root;
    padding: 20px 60px;
}

.pdf_section {
    width: 100%;
    height: 100%;
}

.img_section {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.img_section img {
    max-width: 100%;
    max-height: 100%;
}

.closeCart {
    font-weight: 300;
    border-radius: 2px;
    font-family: var(--robotoC);
    width: 20px;
    height: 20px;
    display: flex;
    font-size: 40px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    top: 25px;
    right: 25px;
    z-index: 1;
}