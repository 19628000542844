.addSubCategoryPopup {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 50px 100px;
}

.itemDesIcon {
    cursor: pointer;
}

.addSub_header {
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px;
    border-bottom: 1px solid rgb(1 41 101 / 20%);
}

.addSub_header p {
    margin: 0;
}

.addSub_header span {
    width: 16px;
    height: 16px;
}

.addSub_body {
    height: calc(100% - 100px);
    width: 100%;
    padding: 40px 5px;
    overflow-y: auto;
}

.section_left {
    width: 50%;
    height: 100%;
    padding: 0px;
    display: flex;
    align-items: flex-end;
    justify-content: right;
}

.section_right {
    width: 50%;
    height: 100%;
    padding: 30px;
    background-color: #f9f8f8;
    position: relative;
}

.section_inner {
    width: 100%;
    height: calc(100% - 50px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.input_group {
    width: 85%;
}

.input_label {
    font-size: 15px;
    margin-bottom: 5px;
    font-weight: 200;
    line-height: 19px;
    position: relative;
    text-transform: uppercase;
    display: block;
    font-size:'24px';
    font-weight: 500;
    line-height: '19px';
}

.inputs {
    background-color: #fff;
    border: 1px solid rgba(255, 0, 0, .2);
    border-radius: 0;
    color: #012965;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 200;
    height: 76px;
    margin-bottom: 2px;
    margin-top: 8px;
    padding: 0 10px;
    text-align: left;
    width: 100%;
    text-indent:20px ;
}

.phone_inputs {
    background-color: #fff !important;
    border: 1px solid rgba(255, 0, 0, .2) !important;
    border-radius: 0 !important;
    color: #012965 !important;
    font-family: Poppins !important;
    font-size: 14px !important;
    font-weight: 200 !important;
    height: 48px !important;
    margin-bottom: 2px !important;
    /* padding: 0 10px !important; */
    text-align: left !important;
    width: 100% !important;
}
.phone_btn {
    border: 1px solid rgba(255, 0, 0, .2) !important;
}

.phone_inputs:focus-visible,
.inputs:focus-visible {
    outline: none;
}

.text_area {
    background-color: #fff;
    border: 1px solid rgba(255, 0, 0, .2);
    border-radius: 0;
    color: #012965;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 200;
    margin-bottom: 2px;
    padding: 0 10px;
    text-align: left;
    min-height: 150px;
    width: 100%;
    resize: none;
    padding: 10px;
}

.text_area:focus-visible {
    outline: none;
}

.icon_section {
    height: 50px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 20px;
}

.addSub_footer {
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px;
    border-top: 1px solid rgb(1 41 101 / 20%);
}

.imageAddBtn {
    height: 38px;
    border: 1px solid #012965;
    background-color: #012965;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    cursor: pointer;
}

.featureItemAddBtn {
    height: 38px;
    border: 1px solid #012965;
    background-color: #012965;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.cancelBtn {
    border: 1px solid #012965;
    background-color: transparent;
    color: #012965;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: var(--robotoC);
    height: 24px;
    width: 120px;
    margin-right: 15px;
}

.submitBtn {
    border: 1px solid #012965;
    background-color: #012965;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: var(--robotoC);
    height: 24px;
    width: 120px;
}

.next_btn {
    border: 1px solid #012965;
    background-color: #012965;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: var(--robotoC);
    height: 45px;
    min-width: 100px;
    border-radius: 2px;
    padding: 2px 10px;
    text-transform: uppercase;
    cursor: pointer;
}

.img_view_container {
    width: 100%;
    height: 150px;
    border: 1px dotted #ced4da;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
}

.img_view_container img {
    max-height: 100%;
    max-width: 100%;
}

.add_fields {
    cursor: pointer;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 500;
    background-color: #012965;
    color: #fff;
    border-radius: 4px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.input_field_item:not(:valid) {
    border-color: rgb(255 0 0 / 20%);
}

.image_viewer {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100vh;
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
}

.image_viewer span {
    width: 50px;
    height: 50px;
    background-color: #fff;
    color: #4d4242;
    position: fixed;
    font-size: 34px;
    text-align: center;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.image_viewer img {
    width: auto;
    max-height: 100vh;
}

.btn_section {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.preview_section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 50px;
    flex-direction: column;
}

.preview_header {
    width: 70%;
    height: 50px;
    display: flex;
    align-items: center;
}

.info_container {
    width: 70%;
    height: calc(100% - 100px);
    overflow-y: auto;
}

.info_item {
    color: #012965;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 10px;
    display: flex;
}

.info_label {
    text-transform: uppercase;
}

.info_header {
    font-size: 20px;
    font-weight: 300;
    line-height: 45px;
}

.all_info {
    padding-left: 40px;
}

.info_item_label {
    width: 200px;
    margin: 0;
}

.info_item_value {
    width: calc(100% - 280px);
    margin: 0;
    display: flex;
    align-items: center;
}

.info_item_edit {
    width: 80px;
    margin: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}