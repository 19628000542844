.bannerSection {
    /* width: calc(100% - 84px); */
    width: 100%;
    height: 520px;
    /* margin: 40px 42px 17px; */
    /* margin-bottom: 20px; */
    border-bottom: 1px solid rgb(1 41 101 / 5%);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.full_bannerSection {
    width: calc(100%);
    height: 300px;
    margin: 0 0 17px;
    border-bottom: 1px solid rgb(1 41 101 / 5%);
    /* border-top: none; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.bannerOnlySearch {
    border-top: none;
    height: 490px;
}

.bannerSliderSection {
    width: 50%;
    height: 100%;
    display: flow-root;
    background-color: bisque;
}

.full_bannerSliderSection {
    width: 100%;
    height: 100%;
    display: flow-root;
    background-color: bisque;
}

.bannerContentSection {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 80px 65px;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: space-between;
}

.bannerContentSection p {
    text-align: left;
    font-family: var(--robotoC);
    width: 311px;
    letter-spacing: 0.8px;
}

.bannerContent1 {
    color: #ff0000;
    font-weight: 300;
    font-size: 40px;
    line-height: 34px;
}

.bannerContent2 {
    color: #012965;
    font-weight: 300;
    font-size: 18px;
    line-height: 20px;
}

.bannerContent3 {
    color: #012965;
    font-weight: 600;
    font-size: 18px;
}

.bannerContent4 {
    color: #012965;
    font-weight: 300;
    font-size: 18px;
    line-height: 20px;
}

.bannerSlideItem {
    height: 100%;
}

.bannerSlideItem img {
    /* transform: scale(1.11) !important; */
    min-width: 100%;
    height: 100%;
    object-fit: cover;
}

.FooterbannerSection {
    width: 100%;
    height: 300px;
    border: 1px solid rgb(1 41 101 / 5%);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.deshibannerOnlySearch {
    background-image: url('../deshi-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border: none;
}

.search_container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(255 255 255 / 90%);
}

.deshibannerOnlySearch .search_container {
    background-color: rgb(1 41 101 / 80%);
}

.search_container_inner {
    text-align: center;
    background-color: transparent;
    min-height: auto;
    width: calc(60% - 60px);
}

.bannerOnlySearch .search_container_inner {
    background-color: transparent;
    min-height: auto;
    width: 60%;
}

.search_container_title {
    color: #ff0000;
    font-size: 30px;
    font-family: var(--Poppins);
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.deshibannerOnlySearch .search_container_title {
    color: #fff;
}

.search_container_input_section {
    
    margin-bottom: 10px;
    position: relative;
}

.search_container_input {
    width: 100%;
    height: 40px;
    border-radius: 2px;
    border: 1px solid rgb(255 0 0 / 10%);
    padding: 0 40px 0px 15px;
    font-family: var(--Poppins);
    font-size: 16px;
    font-weight: 200;
    color: #012965;
}

.search_container_input::placeholder {
    color: rgb(1 41 101 / 30%);
}

.search_icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    color: rgb(255 0 0 / 60%);
}

.search_container_input:focus-visible {
    outline: none;
}

.search_container_bottom_text {
    width: 100%;
}

.bottom_text1 {
    font-family: var(--Poppins);
    color: #012965;
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 22px;
}

.deshibannerOnlySearch .bottom_text1 {
    color: #fff;
}

.bottom_text2 {
    color: #012965;
    font-size: 20px;
    font-family: var(--Poppins);
    font-weight: 200;
}

.deshibannerOnlySearch .bottom_text2 {
    color: #fff;
}

.search_result_container {
    width: 100%;
    position: absolute;
    top: 40px;
    height: auto;
    background-color: #fff;
    z-index: 5;
    border: 1px solid rgb(255 0 0 / 10%);
    border-top: none;
    padding: 15px;
    box-sizing: border-box;
}

.searchItemContainer {
    overflow-y: auto;
    min-height: 100px;
    max-height: 400px;
    width: 100%;
    padding: 0 0;
}


.srItemHolder {
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.itemInCart .sriDetails {
    background-color: rgb(1 41 101 / 5%);
}

.srItemAdd {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
}

.srItem {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 2px;
    text-decoration: none;
}

.sriDetails {
    padding: 0 10px 0 6px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.srItem:hover .sriDetails {
    background-color: rgb(1 41 101 / 10%);
}

.sri_image {
    width: 40px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sri_image img {
    max-width: 100%;
    max-height: 100%;
}

.sri_name {
    width: calc(100% - 90px);
    padding-left: 5px;
}

.sri_name p {
    font-family: var(--robotoC);
    font-weight: 300;
    font-size: 15px;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #012965;
    text-transform: capitalize;
}

.sri_price {
    width: 90px;
    font-family: var(--robotoC);
    font-weight: 300;
    font-size: 15px;
    text-align: right;
    color: #012965;
}

.notFound {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--robotoC);
    font-weight: 300;
    font-size: 16px;
    color: #012965;
}

._btn_add {
    border-radius: 50%;
    width: 28px;
    height: 28px;
    border: 1px solid rgb(255 0 0 / 20%);
    background-color: #fff;
    color: #ff0000;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.alreadyIncart {
    background-color: #ff0000;
    color: #fff;
}

.cart_total_section {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-radius: 2px;
    width: 190px;
    height: 27px;
    cursor: pointer;
    top: 25px;
    right: 99px;
    text-decoration: none;
    background-color: #fff;
}

.pro_qty {
    font-size: 15px;
    font-family: var(--robotoC);
    font-weight: 300;
    margin-left: 2px;
    color: #ff0000;
}

.cartTotal {
    color: #ff0000;
    font-weight: 300;
    font-family: var(--robotoC);
    font-size: 15px;
    margin-left: 8px;
}

.fractionValue {
    font-size: 12px;
}
.sub_cat_container{
    width: 100%;
    padding-top: 30px;
}

.sub_cat_row{
    padding: 0px 90px;
    /* display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center; */
}

.sub_cats{
    width: 75px;
    height: 85px;
}
.sub_cat_link{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    float: left;
    margin-top: 10px;
    cursor: pointer;
    text-decoration: none;
    border: 1px solid transparent;
    border-radius: 2px;
    padding-top: 2px;
}
.sub_cat_link img{
    width: 28px;
    height: 28px;
    border-radius: 100%;
    object-fit: cover;
    margin-bottom: 7px;
}
.sub_cat_link p{
    width: 92%;
    height: 35px;
    font-family: var(--robotoC);
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.02em;
    color: #012965;
    text-align: center;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

}

@media (max-width: 768px) {
    .bannerSection {
        width: calc(100% - 0px);
        height: auto;
        margin: 0px;
        flex-direction: column;
        border: none;
    }

    .bannerSliderSection {
        width: 100%;
        min-height: 150px;
    }

    .FooterbannerSection .bannerSliderSection {
        width: 100%;
        min-height: 100%;
    }

    .bannerContentSection {
        width: 100%;
        padding: 10%;
    }

    .bannerContentSection p {
        width: 100%;
        margin-bottom: 15px;
    }

    .bannerContentSection p br {
        display: none;
    }

    .bannerContentSection p:last-child {
        margin-bottom: 0;
    }

    .bannerContent1 {
        font-size: 28px;
        line-height: 28px;
    }


    .search_container_title {
        font-size: 28px;
        text-align: center;
    }

    .bottom_text1 {
        text-align: center;
        font-size: 20px;
    }

    .bottom_text2 {
        font-size: 18px;
        text-align: center;
    }

    .full_bannerSection {
        border-top: 1px solid rgb(1 41 101 / 5%);
    }

    .search_container_inner {
        width: 80%;
    }

    .bannerOnlySearch .search_container_inner {
        width: 80%;
    }
    .sub_cat_row {
        padding: 0px 30px;
    }
    .sub_cat_link img {
        width: 28px;
        height: 28px;
        border-radius: 100%;
        object-fit: cover;
        margin-bottom: 7px;
    }
}