.footerSection {
  height: 20px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  background-color: transparent;
  position: relative;
}

.footerInnerContainer {
  height: 20px;
  width: calc(100%);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
  box-shadow: 0 -1px 1px -1px rgb(1 41 101 / 12%), 0 -1px 1px -1px rgb(1 41 101 / 7%);
  border: 1px solid #ccd4e0;
}

.footer_ul {
  width: 50%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  margin: 0 !important;
  margin-left: 60px !important;
  height: 20px;
}

.footerItem {
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.footerItem a {
  color: #012965;
  font-family: var(--Poppins);
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  text-decoration: none;
}

.socialIcons {
  display: none;
}


.uk_bd_flag_container {
  width: auto;
  height: 20px;
  display: flex;
  align-items: center;
  column-gap: 2px;
  position: absolute;
  right: 1px;
  bottom: 0;
}

.flag_img {
  width: auto;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flag_img .flag {
  width: 23px;
  height: 14px;
  vertical-align: unset;
}

.contact_section {
  margin-right: 15px;
  color: #012965;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  text-decoration: none;
  cursor: pointer;
  /* display: flex; */
  /* align-items: center; */
  /* height: 20px; */
}

.contact_section .icons {
  width: auto;
  height: 16px;
  vertical-align: unset;
  margin-right: 5px;
}

.contact_section a {
  color: #012965;
  text-decoration: none;
}

@media (max-width: 768px) {
  .footerSection {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;
    justify-content: space-between;
    border-top: 1px solid #ccd4e0;
    z-index: 1;
  }

  .footerInnerContainer {
    width: 100%;
    margin: 0;
    border: none;
    box-shadow: unset;
    justify-content: flex-start;
    
  }

  .footer_ul {
    width: calc(100% - 54px);
    padding: 0 15px;
    overflow-x: auto;
    margin-left: 0 !important;
  }

  .footerItem {
    margin-right: 15px;
  }

  .footerItem:last-child {
    margin-right: 0;
  }

  .socialIcons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 100%;
    color: #ff0000;
  }

  .footerItem a {
    white-space: nowrap;
  }
}