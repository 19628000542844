p {
    margin: 0;
    padding: 0;
}

.mainContainer {
    width: 100%;
    height: calc(100vh - 50px);
    font-family: 'Poppins', sans-serif;
    background: linear-gradient(rgba(1, 41, 101, 0.7) 100%, rgba(1, 41, 101, 0.7)100%), url('../images/travel.png');
    /* background: linear-gradient(rgba(255, 253, 253, 0.5) 100%, rgba(255, 255, 255, 0.5)100%), url('../images/Study\ in\ the\ UK.png'); */
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    position: relative;
    scroll-snap-align: start;
    scroll-snap-stop: always;
}

.propertyMain{
    background: linear-gradient(rgba(1, 41, 101, 0.7) 100%, rgba(1, 41, 101, 0.7)100%), url('../images/property.png');
}

.leftContainer {
    /* position: fixed;
    top: 30px;
    left: 0; */
    color: #fff;
    width: 100%;
    height: 100%;
    padding: 40px 20%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* background: rgba(0, 48, 25, .75); */
    text-align: center;
}

.rightContainer {
    /* position: fixed;
    top: 30px;
    right: 0; */
    width: 50%;
    height: 100%;
    padding: 60px 76px;
    /* display: flex;
    flex-direction: column;
    justify-content: flex-start; */
    display: flow-root;
    /* align-items: center; */
    color: #003019;
    overflow-y: auto;
}

.logoText {
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    width: 100%;
    display: flex;
    height: 40px;
    cursor: pointer;
}

.ukbdlogo {
    width: 7%;
    text-align: start;
}

.ukbdlogo img {
    height: 27.5px;
    width: 27.5px;

}

.ukbdtext {
    width: 80%;
    font-weight: 200;
    text-transform: uppercase;
    text-align: start;
}

.ukbdtext .title {
    font-weight: 100;
    font-size: 34px;
    margin: 0;
    padding: 0;
    line-height: .8;
    margin-bottom: 10px;
}

.studyBtns {
    width: 100%;
    padding-right: 30px;
    display: flex;
    align-items: center;
}

.headBtns {
    margin-right: 6px;
    padding: 0 15px;
    font-size: 11px;
    background-color: rgba(0, 0, 0, .35);
    border-radius: 20px;
    color: #ffffffd4;
    height: 17px;
    width: auto;
    font-family: 'Poppins';
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 1px;
}

.returnArrow {
    position: fixed;
    top: 40px;
    right: calc(50% - 40px);
}

.returnArrow i {
    font-size: 26px;
}

.hamMenu {
    position: fixed;
    top: 40px;
    right: 20px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: rgba(0, 48, 25, .75);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 100ms ease-in-out;
}

.hamMenu:hover {
    background-color: rgba(0, 48, 25, .50);
}

.line1_horizontal {
    width: 25px;
    height: 1px;
    border: 1.7px solid rgb(255, 255, 255);
    border-radius: 1px;
    margin-bottom: 3px;
}

.line2_horizontal {
    width: 25px;
    height: 1px;
    border: 1.7px solid rgb(255, 255, 255);
}

.line3_horizontal {
    margin-top: 3px;
    width: 25px;
    height: 1px;
    border: 1.7px solid rgb(255, 255, 255);
}

.uk_bd_flag_container {
    width: auto;
    height: 25px;
    display: flex;
    column-gap: 2px;
    position: absolute;
    right: 0;
    bottom: 0%;
}

.flag_img {
    width: auto;
    height: 18px;
}

.flag_img img {
    width: 100%;
    height: 100%;
}

.topText {
    padding: 30px 12px 20px 12px;
}

.topTextN {
    padding: 30px 0 0;
    margin: auto;
    height: calc(100% - 40px);
    overflow-y: auto;
    display: flex;
    align-items: center;
}

.topinnertext {
    text-align: left;
    font-family: 'Poppins', sans-serif;
    font-weight: 200;
    font-size: 16px;
    line-height: 21px;
    word-spacing: 3.5px;
    letter-spacing: 0.6px;
}

.imageBox {
    padding: 0 30px;
    margin: auto;
}

.imageBox img {
    width: 100%;
    height: 100%;
}

.bottomText {
    padding: 20px 30px 0px 30px;
}

.bottominnertext {
    text-align: end;
    font-family: 'Poppins', sans-serif;
    font-weight: 100;
    font-size: 18px;
    line-height: 26px;
    word-spacing: 3px;
}

::placeholder {
    color: rgba(0, 48, 25, .1);
}

input[type=checkbox]:checked {
    accent-color: #003019;
    background-color: #003019;
}

input[type=date] {
    color: rgba(0, 48, 25, .9);
}

input:focus {
    outline: none !important;
    color: rgba(0, 48, 25, .9);
}

/*Right Side*/
.rightTopBtns {
    position: fixed;
    top: 40px;
    right: 70px;
    display: flex;
}

.rightTopBtns .headBtns:first-child {
    margin: 0px 6px;
}

.rightTopBtns .headBtns {
    background-color: rgba(0, 48, 25, .75);
}

.comingSoon {
    margin: auto;
}

.comingSoonText {
    font-size: 46px;
}

.rightTopText {
    width: 100%;
    text-align: end;
    /* padding-top: 22px; */
}

.rightTopinnertext {
    font-size: 22px;
    font-weight: 200;
    text-transform: uppercase;
    word-spacing: 1.9px;
    line-height: 1;
    text-align: end;
}

.rightTopinnertext2 {
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1;
}

.formContainer {
    width: 100%;
}

.rightForms {
    width: 100%;
    text-align: end;
    /* padding-top: 30px; */
    margin: auto;
    overflow: scroll;
}

.rightForms::-webkit-scrollbar {
    display: none;
}

.rightFormTop input {
    height: 22px;
    width: 80%;
    border-radius: 0px;
    border: solid 1px rgb(255 0 0 / 20%);
    font-size: 15px;
    padding: 0 10px;
    text-align: end;
    margin-bottom: 1px;
    color: rgba(0, 48, 25, .9);
}

.rightFormTop input:focus {
    outline: none;
}

.input_label {
    font-size: 15px;
    font-weight: 300;
    padding: 0 10px;
    position: relative;
    top: 3px;
}

.rightFormMiddle input {
    height: 22px;
    width: 50%;
    border-radius: 0px;
    border: solid 1px rgb(255 0 0 / 20%);
    font-size: 15px;
    padding: 0 10px;
    color: rgba(0, 48, 25, .9);
    text-align: end;
    margin-bottom: 2px;
}

.rightFormMiddle input:focus {
    outline: none;
}

.rightFormBottom label {
    font-size: 15px;
    font-weight: 300;
    padding: 0 10px;
    position: relative;
    top: 3px;
}

.rightFormBottom input {
    height: 22px;
    width: 80%;
    border-radius: 0px;
    border: solid 1px rgb(255 0 0 / 20%);
    font-size: 15px;
    padding: 0 10px;
    text-align: end;
    margin-bottom: 2px;
    color: rgba(0, 48, 25, .9);
}

.inputGroup {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end
}

.inputGroup input {
    height: 22px;
    width: 50%;
    border-radius: 0px;
    border: solid 1px #ff313135;
    font-size: 15px;
    padding: 0 10px;
    text-align: end;
    margin-bottom: 2px;
    border-left: none;
}

.dropDown {
    float: right;
    height: 22px;
    width: 80%;
    border-radius: 0px;
    border: solid 1px rgb(255 0 0 / 20%);
    font-size: 15px;
    padding: 0 10px;
    text-align: end;
    margin-bottom: 1px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #fff;
}

.dropDownMiddle {
    float: right;
    height: 22px;
    width: 50%;
    border-radius: 0px;
    border: solid 1px rgb(255 0 0 / 20%);
    font-size: 15px;
    padding: 0 10px;
    text-align: end;
    margin-bottom: 1px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    top: 3px;
    background-color: #fff;
}

.logoNum {
    height: 22px;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: solid 1px #ff313135;
    border-right: none;
    background-color: #FFF;
    padding: 0 6px;
}

.logoNum img {
    height: 15px;
}

.logoNum p {
    padding: 0 10px;
}

.termsAndCondition {
    width: 100%;
    text-align: end;
    padding: 5px 5px;
}

.termsAndCondition input {
    margin: 0 5px;
    height: 16px;
    width: 16px;
    border-radius: 0;
    position: relative;
    top: 3px;
}

.submitBtn {
    width: 100%;
    text-align: end;
}

.submitBtn button {
    height: 22px;
    width: 50%;
    background-color: #ff3131;
    color: #fff;
    border: none;
}

.submitBtn button:hover {
    background-color: #ff313135;
}

.rightBottomText {
    width: 100%;
    text-align: end;
    padding-top: 10px;
}

.rightBottominnertext {
    font-size: 12px;
    line-height: 14px;
    text-align: justify;
}

.slide_text_title {
    font-size: 21px;
    font-weight: 600;
}

.slide_text_body {
    font-size: 21px;
    font-weight: 100;
}

._property_ .slide_text_title{
    font-size: 20px;
}
._property_ .slide_text_body{
    font-size: 18px;
}


@media (max-width: 768px) {
    .mainContainer {
        display: block;
        overflow-y: auto;
        height: calc(100vh - 60px);
    }

    .leftContainer {
        width: 100%;
        padding: 30px;
        display: flow-root;
    }

    .rightContainer {
        width: 100%;
        padding: 30px;
    }

    .ukbdlogo {
        width: 40px;
    }

    .ukbdtext {
        width: calc(100% - 40px);
    }

    .ukbdtext .title {
        font-size: 24px;
        line-height: 28px;
        margin: 0;
    }

    .studyBtns {
        flex-wrap: wrap;
        padding: 0;
    }

    .headBtns {
        margin-bottom: 6px;
    }

    .topTextN {
        padding: 0px 0;
        display: flow-root;
    }

    .topText {
        padding: 20px 12px;
    }

    .bottomText {
        padding: 20px 12px;
    }

    .bottominnertext {
        font-size: 16px;
        line-height: 22px;
        word-spacing: 2px;
    }

    .rightTopinnertext {
        font-size: 16px;
        line-height: 20px;
    }

    .rightFormTop input {
        width: 90%;
    }

    .dropDown {
        width: 90%;
    }

    .rightFormMiddle input {
        width: 90%;
    }

    .dropDownMiddle {
        width: 90%;
    }

    .rightFormBottom input {
        width: 90%;
    }

    .inputGroup input {
        width: 60%;
    }

    .submitBtn button {
        width: 90%;
    }
}