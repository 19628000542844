.authMsgSection.ukbd {
  background: linear-gradient(rgba(255, 0, 0, 0.7), rgba(255, 0, 0, 0.7)), url("../images/london.png");
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
}

.authMsgSection.usbd {
  background: linear-gradient(rgba(255, 0, 0, 0.7), rgba(255, 0, 0, 0.7)), url("../images/NewYork.png");
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
}

.authMsgSection.jpbd {
  background: linear-gradient(rgba(255, 0, 0, 0.7), rgba(255, 0, 0, 0.7)), url("../images/tokyo.png");
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
}

.authMsgSection {
  width: 100%;
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 1px;
  /* background: linear-gradient(rgba(255, 0, 0, 0.7), rgba(255, 0, 0, 0.7)),url("../images/london.png"); */
  /* background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat; */
}


.authMsgSection .authMsg {
  width: 100%;
  height: 240px !important;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 0 60px;
}

.authMsgSection p {
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 55px;
  text-align: center;
  color: #ffffff;
  width: 100%;
  height: auto;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.authMsgSection p strong {
  font-weight: 700;
}

.authMsgSection2 {
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 1px;
  padding-top: 24px;
}

.authMsgSection2 p {
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  color: #ff0000;
  width: 100%;
  height: auto;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.authMsgSection2 p strong {
  font-weight: 700;
}

.authMsgSection2 .authMsg {
  width: 100%;
  height: 120px !important;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 0 60px;
}

.banner_section {
  width: 100%;
  float: left;
  height: 300px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}

._banner {
  height: 100%;
  width: 33.333%;
  position: relative;
  overflow: hidden;
  margin: 20px 0;
}

.banner_section img {
  width: 100%;
  min-height: 100%;
  object-fit: cover;
  object-position: center;
  background-position: center;
  background-size: cover;
}

.overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(1, 41, 101, 0.4);
  overflow: hidden;
  width: 100%;
  height: 100%;
  transition: 0.5s ease;
  backdrop-filter: blur(2px);
}

._banner:hover .overlay {
  bottom: 0;
  height: 100%;
  width: 100%;
}

.top-overlay {
  bottom: 100%;
  height: 0;
}

._banner:hover .top-overlay {
  bottom: 0;
}

.bottom-overlay {
  top: 100%;
}

._banner:hover .bottom-overlay {
  top: 0;
}

.text {
  font-style: normal;
  font-family: var(--robotoC);
  color: white;
  font-size: 24px;
  font-weight: 300;
  line-height: 27px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 70%;
}

.product_section {
  width: 100%;
  float: left;
  padding: 12px 30px 40px;
  padding-top: 3px;
}

.parent_for_product {
  width: 100%;
  min-height: 600px;
  float: left;
  /* display: grid; */
  /* grid-template-columns: auto auto auto auto; */
  /* gap: 24px; */
  /* row-gap: 24px; */

  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}

.pagination_section {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 15px 0 18px;
  height: 100px;
}

.pagination_section .loader_ico img {
  height: 50px;
  width: 100px;
  scale: 2;
}

.total_items_count {
  height: 25px;
  display: flex;
  justify-content: center;
  color: #012965;
  font-size: 16px;
  font-family: var(--robotoC);
  font-weight: 200;
}

.loadMore_data {
  color: #012965;
  font-family: var(--robotoC);
  letter-spacing: 0.6px;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid rgb(1 41 101 / 15%);
  border-radius: 2px;
  min-width: 200px;
  padding: 0 30px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  /* transition: background-color 500ms ease-in-out, color 500ms ease-in-out; */
}

.loadMore_data:hover {
  background-color: #012965;
  color: #fff;
}

button.pagination-button {
  height: 24px;
  font-size: 16px;
  font-weight: 300;
  font-family: var(--robotoC);
  border: none;
  background-color: transparent;
  color: #012965;
  border-radius: 2px;
  cursor: pointer;
  margin: 0 5px;
  user-select: none;
}

button.pagination-button:hover {
  background-color: #e5e9ef;
}

button.pagination-button.active {
  background-color: #e5e9ef;
  pointer-events: none;
}

.bottom_banner_section {
  padding: 12px 1px 0;
  display: flow-root;
  /* align-items: center;
  justify-content: space-between; */
}

.bottom_banner_title {
  font-family: 'Poppins';
  font-style: normal;
  line-height: 30px;
  letter-spacing: 0.5px;
  padding: 0 0 5px 90px;
  color: #ff3131;
  font-weight: 100;
  font-size: 32px;
  text-transform: uppercase;
}

.bottom_banner {
  width: 100%;
  height: 200px;
  border-radius: 2px;
  border: 1px solid rgba(1, 41, 101, 0.2);
  border-left: none;
  border-right: none;
}

.sitemap_section {
  width: 100%;
  padding: 0 0;
}

.sitemap_container {
  min-height: 300px;
  width: 100%;
  display: flow-root;
  background-color: rgb(223 227 233 / 30%);
}

.page_nav {
  width: 100%;
  height: 20px;
  background-color: transparent;
  /* border-bottom: 1px solid #e7eaee; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 42px;
  letter-spacing: 0.5px;
  position: sticky;
  top: 0;
  z-index: 4;
}

.page_nav span {
  font-size: 12px;
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 300;
  margin-right: 5px;
  line-height: 20px;
  user-select: none;
  color: #012965;
}

.page_nav span:last-child {
  margin-right: 0;
}

.page_nav a {
  text-decoration: none;
  color: #012965;
}

.breadCumLabel {
  font-size: 12px;
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 600;
  margin-right: 5px;
  line-height: 21px;
  user-select: none;
  color: #ff0000;
  background-color: #ffffff;
  padding-right: 5px;
  letter-spacing: 0.8px;
  font-weight: 500;
}

/* Style the slider track */
.noUi-horizontal .noUi-connect {
  border-radius: 8px;
  /* Adjust the value to control the roundness */
}

/* Style the slider handle */
/* .noUi-handle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #007bff;
  cursor: pointer;
} */
/* Make it round */

/* .noUi-handle {
  position: absolute;
  top: -6px;
  height: 20px;
  width: 20px;
  border-radius: 50px;
  outline: none;
  right: -10px!important;
  left: auto;
  border: 1px solid #d9d9d9;
  background: #fff;
  cursor: default;
  box-shadow: inset 0 0 1px #fff , inset 0 1px 7px #ebebeb , 0 3px 6px -3px #bbb;
} */

.slider-styled .noUi-handle .noUi-touch-area {
  border: 1px solid transparent;
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  width: auto;
  height: auto;
}

/* Show a border when hovering the area the handle responds to */
.slider-styled .noUi-handle:hover .noUi-touch-area {
  border: 1px dashed #7f7f7f;
}

.noUi-horizontal {
  height: 3px;
  border: 1px solid rgb(1 41 101 / 10%);
  border-radius: 10px;
  width: 100%;
}

.noUi-handle {
  background: #fff;
  border: 4px solid #012965;
  /* box-shadow: 0 0 0 4px #fff inset, 0 0 0 9px #012965 inset, 0 0 0 2px #fff; */
}

.noUi-horizontal .noUi-handle {
  width: 6px;
  height: 6px;
  right: -4px;
  top: -4px;
  border-radius: 50%;
}

.noUi-handle:before,
.noUi-handle:after {
  content: none;
}

.noUi-base {
  border-radius: 0;
  border: none;
  box-shadow: none;
  background: #eee;
}

.noUi-connects {
  overflow: hidden;
  border-radius: 3px;
  z-index: 0;
}

.noUi-connect {
  background: #012965;
  width: auto;
}

.noUi-origin {
  width: 0;
}

.noUi-horizontal .noUi-origin {
  height: 0;
  /* z-index: 5; */
}

@media (max-width: 1280px) {
  .authMsgSection p {
    font-size: 32px;
    line-height: 42px;
  }

  .authMsgSection2 p {
    font-size: 32px;
    line-height: 38px;
  }
}

@media (min-width: 1281px) and (max-width: 1440px) {
  .authMsgSection p {
    font-size: 37px;
    line-height: 47px;
  }

  .authMsgSection2 p {
    font-size: 37px;
    line-height: 40px;
  }
}

@media (min-width: 1441px) and (max-width: 1600px) {
  .authMsgSection p {
    font-size: 44px;
    line-height: 54px;
  }

  .authMsgSection2 p {
    font-size: 44px;
    line-height: 48px;
  }
}

@media (min-width: 1601px) and (max-width: 1920px) {
  .authMsgSection p {
    font-size: 52px;
    line-height: 62px;
  }

  .authMsgSection2 p {
    font-size: 52px;
    line-height: 58px;
  }
}

@media (min-width: 1921px) {
  .authMsgSection p {
    font-size: 60px;
    line-height: 70px;
  }

  .authMsgSection2 p {
    font-size: 60px;
    line-height: 68px;
  }
}

@media (max-width: 768px) {
  .product_section {
    padding: 0;
  }

  .authMsgSection {
    height: 100px;
  }

  .authMsgSection2 {
    height: 100px;
    padding-top: 0px;
    overflow: hidden;
  }

  .authMsg {
    height: 100px;
    padding: 0 10px;
  }

  .authMsgSection p {
    font-size: 16px;
    line-height: 20px;
  }

  .authMsgSection2 p {
    font-size: 16px;
    line-height: 20px;
  }

  .banner_section {
    height: 200px;
  }

  ._banner {
    width: 100%;
  }

  .page_nav {
    padding: 5px 12px;
    display: none;
  }

  .page_nav div {
    padding: 0px 5px 0px 0px !important;
  }

  .bottom_banner_title {
    text-align: center;
    font-size: 18px;
    padding: 0px 0px 0px 0px !important;
  }
}