.filter_section {
    width: 100%;
    height: auto;
    padding: 0;
}

.filter_inner_section {
    width: 100%;
    height: 100%;
    background-color: transparent;
    /* border: 1px solid rgb(1 41 101 / 5%); */
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.filter_item {
    width: 100%;
    height: auto;
    padding: 3px 3px;
}

.filter_item:nth-child(3) {
    width: 100%;
}

.select_box {
    width: 100%;
    height: 100%;
    border: none;
    border-bottom: 1px solid rgb(1 41 101 / 15%);
    border-radius: 2px;
    color: #012965;
    background-color: transparent;
    font-family: var(--robotoC);
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
}

.select_box:focus-visible {
    outline: none;
}

.range_filter_item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px 3px;
    flex-direction: column;
}

.range_slider {
    width: 100%;
    height: 24px;
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.range_input {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.price_range_box {
    width: 60px;
    height: 24px;
    border: 1px solid rgb(1 41 101 / 15%);
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #012965;
    font-family: var(--Poppins);
    font-size: 12px;
    font-weight: 300;
}

.resetButton {
    width: 100%;
    height: 24px;
    border: 1px solid rgb(1 41 101 / 15%);
    border-radius: 2px;
    color: #012965;
    background-color: transparent;
    font-family: var(--robotoC);
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    letter-spacing: 0.02em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3px;
    cursor: pointer;
}

.closeCart{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 300;
    color: rgba(255, 0, 0, 0.2);
    border-radius: 2px;
    font-family: var(--robotoC);
    width: 20px;
    height: 20px;
    cursor: pointer;
    top: 15px;
    right: -11px;
    font-size: 30px;
    text-decoration: none;
    background-color: #fff;
}

@media (max-width: 768px) {
    .filter_section {
        padding: 0px 0px 5px;
        position: fixed;
        z-index: 5;
        top: 0;
        background-color: rgb(1 41 101 / 20%);
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding:8%;
    }

    .filter_inner_section {
        height: 100%;
        flex-wrap: wrap;
        background: #fff;
        padding: 30px;
        padding-top: 0;
        position: relative;
    }

    .filter_header {
        display: flex;
        height: 50px;
        width: 100%;
        align-items: center;
        position: relative;
    }

    .filter_header h3 {
        color: #012965;
        font-family: var(--Poppins);
    }

    .filter_item {
        width: 100%;
        height: auto;
        padding: 6px 10px;
    }

    .filter_item:nth-child(5) {
        width: 100%;
        height: auto;
        padding: 6px 10px;
    }

    .range_filter_item {
        width: 100%;
        height: auto;
        padding: 10px 10px;
    }

    .select_box {
        width: 100%;
        height: 40px;
    }

    .order_popup_body {
        width: 100%;
        height: calc(100% - 50px);
    }
}