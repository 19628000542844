.mainContainer {
    width: 100%;
    height: calc(100vh - 50px);
    font-family: 'Poppins', sans-serif;
    background: linear-gradient(rgba(255, 253, 253, 0.5) 100%, rgba(255, 255, 255, 0.5)100%), url('../map-UKBD-FINAL.png');
    /* background: linear-gradient(rgba(255, 253, 253, 0.5) 100%, rgba(255, 255, 255, 0.5)100%), url('../images/Study\ in\ the\ UK.png'); */
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    /* position: relative;
    scroll-snap-align: start;
    scroll-snap-stop: always; */
    position: absolute;
    top: 0;
}

.sponsorship_popup {
    width: 100vw;
    height: 100%;
    background-color: #ffffff;
    padding: 22px 30%;
}

.closeCart {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 300;
    color: rgba(255, 0, 0, 0.2);
    border-radius: 2px;
    font-family: var(--robotoC);
    width: 27px;
    height: 27px;
    cursor: pointer;
    top: 25px;
    right: 42px;
    font-size: 40px;
    text-decoration: none;
    background-color: #fff;
}

.sponsor_header {
    width: calc(100% - 95px);
    height: 75px;
    margin: 0 auto;
}

.sponsor_header_title {
    font-size: 25px;
    font-weight: 500;
    color: #012965;
    text-align: right;
}

.sponsor_header_sub_title {
    font-family: 'Poppins';
    font-size: 24px;
    font-weight: 100;
    text-transform: uppercase;
    word-spacing: 1.9px;
    line-height: 26px;
    text-align: right;
}

.sponsor_body {
    display: flex;
    height: calc(100% - 75px);
    margin: 0 auto;
    overflow-y: auto;
    width: calc(100% - 100px);
    align-items: center;
    flex-wrap: wrap;
}

.redBorder {
    border: 1px solid red;
}

.requiredLabel {
    font-size: 10px;
    color: red;
    padding-right: 5px;
    font-weight: 400;
    letter-spacing: 0.8px;
}

.formContainer {
    display: flow-root;
    /* flex-direction: column; */
    /* align-items: flex-end; */
    /* justify-content: center; */
    width: 100%;
    margin: 0 0 0;
    height: auto;
}

.inputGroup {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    margin-top: 3px;
    position: relative;
}

.inputGroup input:focus {
    background-color: #01296508;
}

.inputGroupTerms {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
}

.termsGroup {
    width: calc(50% + 54px);
    text-align: left;
    padding: 5px 0;
}

.inputLabel {
    font-size: 13px;
    line-height: 19px;
    padding: 0 10px;
    position: relative;
    text-transform: uppercase;
    font-weight: 200;
    color: #012965;
}

.input_label {
    font-size: 15px;
    font-weight: 300;
    padding: 0 10px;
    position: relative;
    top: 3px;
    color: #012965;
}

.input {
    height: 22px;
    width: 62%;
    border-radius: 0px;
    border: 1px solid rgb(255 0 0 / 20%);
    font-size: 14px;
    padding: 0 10px;
    text-align: end;
    margin-bottom: 2px;
    color: #012965;
    font-family: 'Poppins';
    font-weight: 300;
    background-color: #fff;
}

.input:focus-visible {
    outline: none;
}

.inputFull {
    width: 100%;
}

.rightBottom {
    width: 100%;
}

.rightBottomText {
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 400;
    /* text-transform: uppercase; */
    line-height: 1;
}


.dropDown {
    float: right;
    height: 22px;
    width: 80%;
    border-radius: 0px;
    border: solid 1px rgb(255 0 0 / 20%);
    font-size: 15px;
    padding: 0 10px;
    text-align: end;
    margin-bottom: 1px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #fff;
}

.dropDownMiddle {
    float: right;
    height: 22px;
    width: 62%;
    border-radius: 0px;
    border: solid 1px rgb(255 0 0 / 20%);
    font-size: 15px;
    padding: 0 10px;
    text-align: end;
    margin-bottom: 1px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    top: 3px;
    background-color: #fff;
    font-size: 14px;
    color: #012965;
    font-family: 'Poppins';
    font-weight: 200;
}

.logoNumber {
    position: absolute;
    top: 20px;
    left: 5px;
    display: flex;
    height: 20px;
    align-items: center;
    column-gap: 5px;
}

.logoNumber p {
    font-size: 14px;
    color: #012965;
    font-family: 'Poppins';
    font-weight: 200;
}

.logoNumber img {
    width: 24px;
    height: 14px;
}


.termsAndCondition {
    width: 100%;
    text-align: end;
    padding: 5px 5px;
}

.termsAndCondition input {
    margin: 0 5px;
    height: 16px;
    width: 16px;
    border-radius: 0;
    position: relative;
    top: 3px;
}

.submitBtn {
    width: 100%;
    text-align: end;
}

.submitBtn button {
    height: 22px;
    width: calc(50% + 54px);
    background-color: #ff0000;
    color: #fff;
    border: none;
    cursor: pointer;
}

.submitBtn button:hover {
    opacity: 0.6;
}

.uploaded_doc {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.uploaded_doc img {
    max-width: 100%;
    max-height: 100%;
}

.error_label {
    font-size: 10px;
    color: red;
    padding-right: 5px;
    font-weight: 400;
    letter-spacing: 0.8px;
    text-transform: uppercase;
}

.error_input {
    background-color: rgb(255 0 0 /2%);
    border-color: rgb(255 0 0 / 100%);
}

.assessmentJob_title {
    font-size: 14px;
    font-weight: 500;
    color: #012965;
    font-family: 'Poppins';
    text-align: right;
}


.info_section .inputGroupTerms {
    justify-content: flex-start;
}

.info_section .submitBtn {
    text-align: start;
}

.info_container {
    margin-bottom: 20px;
}
.info_item {
    color: #012965;
    margin-bottom: 10px;
    font-weight: 300;
    font-size: 14px;
}

.documents_container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    column-gap: 10px;
    margin: 6px 0 8p;
}

@media (max-width: 768px) {
    .sponsorship_popup {
        padding: 20px 30px;
    }

    .sponsor_header {
        width: 100%;
        height: 60px;
    }

    .sponsor_header_title {
        font-size: 20px;
        text-align: left;
    }

    .sponsor_header_sub_title {
        font-size: 18px;
        text-align: left;
        margin-top: 10px;
    }

    .closeCart {
        width: 20px;
        height: 20px;
        top: 20px;
        right: 20px;
        font-size: 30px;
    }

    .sponsor_body {
        height: calc(100% - 70px);
        width: 100%;
    }

    .inputGroup {
        margin-top: 8px;
    }

    .input {
        width: 100%;
    }

    .inputFull {
        width: 100%;
    }

    .dropDownMiddle {
        width: 100%;
    }

    .termsGroup {
        width: 100%;
    }

    .submitBtn button {
        width: 100%;
    }
    .assessmentJob_title{
        text-align: left;
    }
}