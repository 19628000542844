.sitemap_top {
    padding: 100px 100px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sitemap_logo_sec {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.sitemap_feedback_sec {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.siteMapLogoHolder {
    width: 40px;
    height: 40px;
}

.pageLogo {
    width: 100%;
    height: 100%;
}

.pageTitle {
    float: left;
    font-size: 18px;
    font-family: var(--robotoC);
    font-weight: 500;
    color: #012965;
}

.feedback_title {
    color: #012965;
    line-height: 30px;
    display: flex;
    align-items: center;
}

.sitemap_Bottom {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: calc(100% - 80px);
    padding: 0 100px;
}

.sitemap_Bottom_col {
    width: 75%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
}

.sitemap_Bottom_col_right {
    width: 25%;
    display: flow-root;
    padding-left: 50px;
}

.sitemap_Bottom_col_right .child_element:last-child {
    border-bottom: none;
}

.parent_element {
    font-family: var(--Poppins);
    color: #012965;
    font-size: 14px;
    font-weight: 500;
    height: 30px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgb(1 41 101 / 20%);
}

.child_element_group {
    width: calc(25% - 8px);
    margin-bottom: 30px;
}

.child_element {
    font-family: var(--Poppins);
    color: #012965;
    font-size: 12px;
    font-weight: 500;
    height: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    /* padding: 0 5px; */
    text-transform: uppercase;
    border-bottom: 1px solid rgb(1 41 101 / 20%);
}

.grand_child_group {
    padding-left: 15px;
}

.grand_child {
    font-family: var(--Poppins);
    color: #012965;
    font-size: 12px;
    font-weight: 300;
    height: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
}
.grand_child:hover {
    text-decoration: underline;
}

.social_media_part {
    width: 100%;
    display: flex;
    padding: 25px 100px 100px;
    column-gap: 10px;
    justify-content: flex-start;
}

.social_icon {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #012965;
    cursor: pointer;
    text-decoration: none;
    /* transition: all 250ms ease; */
}

.social_icon:hover {
    color: #fff;
    border-radius: 50%;
    background-color: #053b8d;
}

@media (max-width: 768px) {
    .sitemap_top {
        padding: 50px 35px 20px;
    }

    .sitemap_Bottom {
        flex-direction: column;
        padding: 0 35px;
        /* width: 100%; */
    }

    .sitemap_Bottom_col {
        width: 100%;
    }

    .social_media_part {
        padding: 25px 50px 50px;
    }

    .child_element_group {
        width: calc(50% - 5px);
        margin-bottom: 15px;
    }
    
    .sitemap_Bottom_col_right {
        width: 100%;
        display: flow-root;
        padding-left: 0;
    }

    .grand_child_group {
        padding-left: 0;
    }
}