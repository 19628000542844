p {
    margin: 0;
    padding: 0;
}

.mainContainer {
    width: 100%;
    height: calc(100vh - 50px);
    font-family: 'Poppins', sans-serif;
    background: linear-gradient(rgba(255, 253, 253, 0.5) 100%, rgba(255, 255, 255, 0.5)100%), url('../landing/uk-map.png');
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    scroll-snap-stop: always;
    scroll-snap-align: start;
}

.leftContainer {
    color: #fff;
    width: 50%;
    height: 100%;
    padding: 0;
    background: rgba(0, 48, 25, .75);
    text-align: center;
    /* display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; */
    display: flow-root;
}


.slideOne {
    height: 100%;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: background-image 1s ease-out;
}


.slideTwo {
    height: 100%;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: background-image 1s ease-out;
}


.overlay_color {
    height: 100%;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 50px 70px;
    /* background-color: rgb(0 48 25 / 90%); */
    transition: background-color 1s ease-in-out, opacity 1s ease-in-out;
    /* Add opacity transition */
    opacity: 1;
}

.logoText {
    font-family: 'Poppins', sans-serif;
    width: 100%;
    display: flex;
    height: 40px;
    cursor: pointer;
    font-weight: 100;
    font-size: 34px;
    margin: 0;
    padding: 0;
    line-height: .8;
    margin-bottom: 10px;
    text-decoration: none;
    color: #fff;
}


.ukbdtext {
    width: 80%;
    font-weight: 200;
    text-transform: uppercase;
    text-align: start;
}

.ukbdtext .title {
    font-weight: 100;
    font-size: 34px;
    margin: 0;
    padding: 0;
    line-height: .8;
    margin-bottom: 10px;
}

.ukbd_text {
    font-family: 'Poppins', sans-serif;
    padding: 0;
    text-align: end;
}

.slideOne .ukbd_text {
    flex-direction: column;
}

.topText {
    padding: 30px 12px 20px 12px;
}

.bottomText {
    padding: 20px 30px 0px 30px;
}

.topTextN {
    padding: 30px 12px 20px 12px;
    margin: auto;
}

.topinnertext {
    text-align: end;
    font-family: 'Poppins', sans-serif;
    font-weight: 200;
    font-size: 16px;
    line-height: 21px;
    word-spacing: 3.5px;
    letter-spacing: 0.6px;
}


.slide_text_title {
    font-size: 14px;
    font-weight: 600;
}

.slide_text_body {
    font-weight: 100;
}

.ukbd_icon {
    width: 60px;
    height: 60px;
    margin: 0 auto;
    cursor: pointer;
    box-shadow:
        1px 0px 1px rgb(1 41 101 / 10%),
        /* Right */
        -1px 0px 1px rgb(1 41 101 / 10%),
        /* Left */
        0px 1px 1px rgb(1 41 101 / 10%),
        /* Bottom */
        0px -1px 1px rgb(1 41 101 / 10%);
    /* Top */
}

.ukbd_icon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 2px;
}

.ukbd_text_lg {
    padding-top: 35px;
    text-align: end;
}

.ukbd_text_eng {
    font-size: 28px;
    font-weight: 100;
    line-height: 1.145;
    word-spacing: 2px;
    margin: 0;
    text-align: center;
}

.ukbd_text_bangla {
    font-family: 'NotoSansBengali';
    /* font-family: 'Poppins'; */
    padding-top: 50px;
    font-size: 23px;
    font-weight: 100;
    line-height: 30px;
    word-spacing: 2px;
    text-align: center;
}

.rightContainer {
    width: 50%;
    height: 100%;
    padding: 50px 76px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}


.applinks {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
}


.applink {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: auto;
    border-radius: 5px;
    /* border: 2px solid rgb(216 216 216 / 50%); */
    cursor: pointer;
    color: #fff;
    /* background-color: #000; */
}

.applink img {
    max-width: 100%;
    max-height: 100%;
}

.productText {
    width: 100%;
    font-family: 'Poppins', sans-serif;
    text-align: end;
    color: #003019;
    /* padding-bottom: 5px; */
}

.productText_eng {
    font-size: 24px;
    font-weight: 300;
    height: 26px;
    line-height: 26px;
    margin-bottom: 20px;
}

.btns {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
}

.rightBtns {
    width: calc(33.33% - 14px);
    display: flex;
    justify-content: flex-end;
    padding-bottom: 20px;
}


.btnBody {
    cursor: pointer;
    font-weight: 200;
    color: #FF0000;
    border: 1px solid #FF0000;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    padding: 0 10px;
    background-color: transparent;
    font-size: 17px;
    line-height: 20px;
    width: 100%;
    box-sizing: border-box;
    text-decoration: none;
}

.btnBody:hover {
    background-color: #FF0000;
    color: #fff;
}

.item_slider_section {
    width: 100%;
    height: calc(100% - 380px);
}

.slides {
    text-align: center;
}


.imgBox {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    overflow: hidden;
    /* box-shadow: 0px 0px 4px grey; */
}

.imgBox img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center !important;
    scale: 1;
}


.endingText {
    width: 100%;
    padding-top: 15px;
}

.endingText_eng {
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    color: #003019;
}

.imageBox {
    padding: 0 30px;
    margin: auto;
}

.imageBox img {
    width: 100%;
    height: 100%;
}

.bottominnertext {
    text-align: end;
    font-family: 'Poppins', sans-serif;
    font-weight: 100;
    font-size: 18px;
    line-height: 26px;
    word-spacing: 3px;
}

.imgSliderContainer {
    width: 100%;
    height: 100%;
}

.arrow_container {
    animation: bounce 1s infinite;
    width: 50px;
    height: 50px;
    position: absolute;
    bottom: 50px;
    left: calc(50% - 25px);
}

.arrow {
    width: 0;
    height: 0;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-bottom: 1px solid #007bff;
    /* 1px thin border, change the color as needed */
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}


.line1_and_line2_container {
    width: 50px;
    height: 50px;
    position: absolute;
    bottom: 40px;
    left: calc(50% - 25px);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: rgb(0 1 10 / 10%);
    z-index: 9;
    animation: bounce 2s infinite;
    border: 1px solid rgb(0 1 10 / 30%);
}

.line1_vertical {
    width: 1px;
    height: 18px;
    border-right: 1px solid rgba(255, 0, 0, 0.6);
    transform: translate(-6px, 0px) rotate(130deg);
}

.line2_vertical {
    width: 1px;
    height: 18px;
    border-right: 1px solid rgba(255, 0, 0, 0.6);
    transform: translate(6px, 0px) rotate(50deg);
}

.line_cursor_box {
    width: 50px;
    height: 50px;
    cursor: pointer;
    position: absolute
}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-10px);
    }

    60% {
        transform: translateY(-5px);
    }
}


@media (max-width: 768px) {
    .mainContainer {
        width: 100%;
        /* height: calc(var(--vh, 1vh) * 91.8); */
        height: calc(100%);
        background: linear-gradient(rgba(255, 253, 253, 0.5) 100%, rgba(255, 255, 255, 0.5)100%), url('../map-UKBD-FINAL.png');
        background-position: 90% 80%;
        background-size: cover;
        overflow-y: auto;
        flex-direction: column;
        flex-wrap: nowrap;
    }

    .leftContainer {
        width: 100%;
        padding: 0;
        background: rgba(0, 48, 25, .75);
        /* height: calc(var(--vh, 1vh) * 91.8); */
        order: 1;
    }

    .landingSlider {
        height: 100%;
        width: 100vw;
    }

    .overlay_color {
        padding: 30px;
    }

    .rightContainer {
        width: 100%;
        padding: 30px;
        /* height: calc(var(--vh, 1vh) * 91.8); */
        order: 0;
    }

    .item_slider_section {
        width: 100%;
        height: calc(100% - 400px);
    }

    .imgSliderContainer {
        width: 84vw;
        height: 100%;
    }

    .slides {
        text-align: center;
        display: flex !important;
        align-items: center;
        justify-content: center;
    }

    .ukbd_icon {
        width: 50px;
        height: 50px;
    }

    .applinks {
        padding: 15px 0;
    }

    .rightBtns {
        width: calc(51% - 14px);
        padding-bottom: 15px;
    }

    .productText_eng {
        font-size: 17px;
        font-weight: 300;
        line-height: 26px;
        padding-bottom: 0;
        margin-bottom: 15px;
    }

    .ukbd_text {
        text-align: center;
    }

    .ukbd_text_lg {
        text-align: center;
    }

    .ukbd_text_eng {
        font-size: 18px;
        line-height: 24px;
    }

    .ukbd_text_bangla {
        font-size: 14px;
        line-height: 22px;
        padding-top: 35px;
    }

    .ukbd_text_eng_lg {
        font-size: 20px;
    }

    .btns {
        margin-bottom: 0px;
    }

    .btn_container {
        width: 70%;
        text-align: center;
        height: auto;
    }

    .btnBody {
        font-size: 15px;
    }

    .endingText_eng {
        font-size: 12px;
    }
}