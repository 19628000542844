.left_sidebar {
  width: 250px;
  height: calc(100%);
  background: rgb(230 233 237 / 97%);
  /* left: 0; */
  float: left;
}

.left_inner {
  width: 100%;
  height: 100%;
}

.hide_hendler {
  width: calc(100% - 249px);
  height: 100%;
  right: -1px;
  z-index: 5;
  display: none;
}

.aside_head {
  height: 75px;
  box-sizing: border-box;
  width: 100%;
  padding: 15px 15px 10px;
  cursor: pointer;
  display: flow-root;
}

.aside_foot {
  height: 30px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.aside_foot p {
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #012965;
}

.aside_container {
  width: 100%;
  height: calc(100% - 30px);
  padding: 40px 15px 0;
}

.logo_text {
  width: calc(100% - 70px);
  float: left;
  display: none;
}

.logo_text_line1,
.logo_text_line3 {
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 20px;
  text-align: right;
  letter-spacing: 0.02em;
  color: #ff0000;
}

.logo_text_line2 {
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 700;
  font-size: 27px;
  line-height: 25px;
  text-align: right;
  letter-spacing: 0.02em;
  color: #ff0000;
}

.logo {
  float: left;
  width: 50px;
  height: 50px;
}

.logo img {
  width: 50px;
  height: 50px;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25));
}

.top_category {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  height: 175px;
}

.topCate_products {
  text-align: center;
  width: 85%;
  float: left;
  cursor: pointer;
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 0 10px;
  margin-bottom: 10px;
  border-radius: 2px;
  border: 1px solid transparent;
}

.topCate_products:hover,
.topCate_products.active {
  border: 1px solid #012965;
}

.topCate_products:last-child {
  margin-bottom: 0;
}

.topCate_products img {
  width: 18px;
  height: 18px;
  margin-top: 2px;
}

.topCate_products .top_items,
.topCate_products a {
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 17px;
  text-align: center;
  margin-left: 10px;
  text-decoration: none;
  letter-spacing: 0.02em;
  color: #012965;
}

.category_list {
  width: 100%;
  height: calc(100% - 199px);
  float: left;
  overflow-y: auto;
  position: relative;
  border-top: 1px solid rgb(1 41 101 / 20%);
  border-bottom: 1px solid rgb(1 41 101 / 20%);
}

/* .category_list::before {
  content: "";
  position: sticky;
  top: 0;
  left: 0;
  height: 1px;
  width: 100%;
  background-color: rgb(1 41 101 / 20%);
  display: flex;
}

.category_list::after {
  content: "";
  position: absolute;
  bottom: 0px;
  height: 1px;
  left: 0;
  width: 100%;
  background-color: rgb(1 41 101 / 20%);
  display: flex;
} */

.group_items {
  float: left;
  width: 100%;
  height: auto;
  padding-bottom: 10px;
}

.group_items:first-child {
  margin-top: 25px;
}

.group_items:last-child {
  margin-bottom: 25px;
  padding-bottom: 0px;
}

.main_category_container {
  height: 24px;
  background: #f5f5f6;
  box-shadow: 0px 1px 1px -1px rgba(0, 0, 0, 0.12),
    0px 2px 4px -1px rgba(0, 0, 0, 0.07);
  border-radius: 2px;
  padding: 0px 4px 0px 10px;
  cursor: pointer;
}

.main_category_container .title {
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #012965;
  display: flex;
  align-items: center;
  width: calc(100% - 32px);
  float: left;
  user-select: none;
  text-decoration: none;
}

/* .main_category_container .title:hover {
  text-decoration: underline;} */

.main_category_container .icon_p {
  width: 25px;
  height: 24px;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 200ms ease;
  font-size: 13px;
  color: #012965;
}

.main_category_container .icon_p.active {
  transform: rotate(180deg);
}

.sub_cate_container {
  width: 100%;
  height: auto;
  float: left;
  user-select: none;
  padding-top: 15px;

  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}

.sub_cate_container li {
  flex-basis: 33.33%;
  height: 85px;
  width: 33.33%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  float: left;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  border-radius: 2px;
}

.sub_cate_container li:hover::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(1 41 101 / 6%);
  border-radius: 2px;
  pointer-events: none;
}

.sub_cate_container li.active {
  border: 1px solid rgb(1 41 101 / 50%);
}

.sub_cate_container a {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  float: left;
  margin-top: 10px;
  cursor: pointer;
  text-decoration: none;
  border: 1px solid transparent;
  border-radius: 2px;
  padding-top: 2px;
}

.sub_cate_container li img {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  object-fit: cover;
}

.sub_cate_container li p {
  width: 92%;
  height: 35px;
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.02em;
  color: #012965;
  text-align: center;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.fav_img_holder {
  background: transparent;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.deliveries_options {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  user-select: none;
  z-index: 2;
}

.deliveries_options .icon {
  display: flex;
  align-items: center;
  height: 24px;
  width: 25px;
  justify-content: center;
  float: right;
  transition: all 200ms ease;
  transform: rotate(90deg);
}

.deliveries_options .icon.active {
  transform: rotate(180deg);
}

.deliveries_options .selection_point {
  width: 100%;
  height: 24px;
  color: #ffffff;
  cursor: pointer;
  background: #012965;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.12),
    0px 2px 4px -1px rgba(0, 0, 0, 0.07);
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
}

.deliveries_options .selected_value {
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.02em;
}

.list_container {
  position: absolute;
  top: 25px;
  background-color: #f9f9f9;
  box-shadow: 0px 1px 3px rgb(0 0 0 / 25%), 0px 4px 28px rgb(180 180 180 / 25%);
  border-radius: 0 0 2px 2px;
  width: 100%;
  z-index: 1;
  padding: 30px;
  display: none;
}

.list_container li {
  text-indent: 5px;
  cursor: pointer;
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.02em;
  color: rgb(1 41 101 / 1);
  border-bottom: 1px solid rgb(1 41 101 / 20%);
  transition: all 200ms ease;
  line-height: 30px;
  font-size: 16px;
}

.list_container li a {
  line-height: 30px;
  color: rgb(1 41 101 / 1);
  text-decoration: none;
}

.list_container li:last-child {
  border-bottom: none;
}

.list_container li:hover {
  background-color: rgb(1 41 101 / 10%);
  color: #012965;
  border-radius: 1px;
}

.deliveries_options:hover .list_container {
  display: block;
}

li.disableItem {
  color: rgb(1 41 101 / 0.5);
  position: relative;
}

.comingSoon {
  content: 'Coming soon';
  background-color: #f9f9f9;
  border-radius: 10px;
  font-size: 10px;
  font-family: var(--robotoC);
  position: absolute;
  color: #012965;
  height: 13px;
  line-height: 13px;
  top: -8px;
  right: 0;
  padding-right: 4px;
}

/* .uk_f {
  padding-left: 26px;
  background-image: url('../images/gb.svg');
  background-repeat: no-repeat;
  background-size: 25px;
  background-position: 0 50%;
} */
/* .bd_f {
  padding-left: 26px;
  background-image: url('../images/bd.svg');
  background-repeat: no-repeat;
  background-size: 25px;
  background-position: 0 50%;
} */

.category_list::-webkit-scrollbar {
  display: none;
}

.hide_nav {
  left: -100%;
}

/* ----------- OnePlus 6 ----------- */
/* @media only screen
and (min-width: 412px) 
and (max-width: 869px) { */

/* For extremely small screen devices (595px and below) */
/* @media only screen and (max-width: 595px) {...} */

/* Small screen devices (600px and above) */
/* @media only screen and (min-width: 600px) {...} */

/* Medium screen devices (768px and above) */
/* @media only screen and (min-width: 768px) {...} */

/* Big screen devices (889px and above) */
/* @media only screen and (min-width: 889px) {...} */

/* Extra big screen devices (1200px and above) */
/* @media only screen and (min-width: 1200px) {...} */

/* For extremely small screen devices (595px and below) */
@media(max-width: 768px) {
  .hide_hendler {
    display: block;
  }

  .left_sidebar {
    position: absolute;
    /* height: calc(var(--vh, 1vh) * 100); */
    height: 100%;
    z-index: 9;
    width: 100%;
    background: transparent;
    display: flex;
    flex-direction: row;
  }

  .left_inner {
    width: 250px;
    height: 100%;
    background: rgb(230 233 237 / 97%);
    transition: 0.3s ease;
    left: 0;
    float: left;
  }

  .left_sidebar.hide_nav .hide_hendler {
    display: none;
  }

  .aside_head {
    display: none;
  }

  .aside_container {
    padding-top: 20px;
    /* height: calc(calc(var(--vh, 1vh) * 100) - 30px); */
    height: calc(100% - 30px);
  }

  .category_list {
    /* height: calc(calc(var(--vh, 1vh) * 100) - 198px); */
    /* height: calc(100% - 164px); */
    height: calc(100% - 25px);
  }

  .top_category {
    height: 140px;
  }
}