.profileContainer {
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.pageTitleSection {
  width: 190px;
  height: 59px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #012965;
  z-index: 99;
  padding: 10px;
}

.profile_header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #0129651c;
}

.profile_header_right {
  width: 100%;
}

.header_right_bottom {
  width: 100%;
  display: flex;
  height: 36px;
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding: 0 28px;
}

.pageLogoHolder {
  width: 40px;
  height: 40px;
  border-radius: 2px;
  overflow: hidden;
  float: left;
  margin-right: 15px;
}

.pageLogo {
  width: 100%;
  height: 100%;
}

.pageTitle {
  float: left;
  font-size: 18px;
  font-family: var(--robotoC);
  font-weight: 500;
}


.innerContainer {
  width: 100%;
  height: 100%;
}

.tabLeft {
  height: 100%;
  width: 260px;
  display: flex;
  align-items: center;
  flex-direction: column;
  float: left;
  justify-content: flex-start;
  padding: 45px 25px;
  background: rgb(230 233 237 / 40%);
}

.tabRight {
  width: calc(100% - 260px);
  height: 100%;
  float: left;
}

.tabs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 35px;
  cursor: pointer;
  border-radius: 2px;
  padding: 0 15px;
  border-bottom: 1px solid rgb(1 41 101 / 20%);
}

.tabs:last-child {
  border-bottom: none;
}

.tab_title {
  font-family: var(--robotoC);
  color: #012965;
}
.signOut{
  position: fixed;
  bottom: 50px;
  left: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 210px;
  height: 35px;
  cursor: pointer;
  border-radius: 2px;
  /* padding: 0 0px; */
  border-bottom: none;
}
.deleteAccount {
  position: fixed;
  bottom: 12px;
  left: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 210px;
  height: 35px;
  cursor: pointer;
  border-radius: 2px;
  /* padding: 0 0px; */
  border-bottom: none;
}

.tabs.active {
  background-color: rgb(1 41 101 / 10%);
}

.tabs:hover {
  background-color: rgb(1 41 101 / 10%);
}

.tabIcon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #012965;
}

.tabName {
  font-family: var(--Poppins);
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  padding: 0 5px;
  color: #012965;
}

.tabHeader {
  height: 40px;
  width: 100%;
  border-bottom: 1px solid rgb(1 41 101 / 20%);
  display: flex;
  align-items: center;
}

.tabHeader h2 {
  font-family: var(--robotoC);
  font-weight: 400;
  font-size: 18px;
  color: #012965;
}

.tabBody {
  height: calc(100% - 40px);
  padding: 25px 180px;
  overflow-y: auto;
}

.tabBodyInner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.tabBody.order .orderDesc {
  font-family: var(--robotoC);
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  color: #012965;
  margin-bottom: 12px;
}

.infoForm {
  width: 50%;
  margin: 0 auto;
}

.inputContainer {
  position: relative;
}

.inputs {
  font-family: var(--robotoC);
  font-style: normal;
  font-size: 16px;
  font-weight: 300;
  height: 42px !important;
  width: 100% !important;
  padding: 8px 20px;
  margin: 8px 0 15px;
  display: inline-block;
  color: #012965;
  letter-spacing: 0.2px;
  box-sizing: border-box;
  border-radius: 2px !important;
  border: 1px solid rgb(255 0 0 / 10%);
}

.inputs:focus-visible {
  border: 1px solid rgb(255 0 0 / 30%) !important;
  outline: none;
}

.inputs:-webkit-autofill {
  transition: background-color 600000s 0s, color 600000s 0s;
}

.save_button {
  background-color: #ff0000;
  color: #ffffff;
  height: 24px;
  padding: 0px 20px;
  margin: 8px 0 0;
  border-radius: 2px;
  border: none;
  cursor: pointer;
  width: 40%;
  float: right;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid rgba(255, 0, 0, 0.2);
}

.save_button:hover {
  background-color: #ff0000;
  color: white;
}

.addAddress {
  width: 100%;
  margin-bottom: 14px;
}

.addressButton {
  background-color: #ffffff;
  color: #ff0000;
  height: 24px;
  padding: 0px 20px;
  border-radius: 2px;
  border: none;
  cursor: pointer;
  width: auto;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid rgba(255, 0, 0, 0.2);
}

.addressButton:hover {
  background-color: #ff0000;
  color: white;
}

.addressListContainer {
  width: 100%;
  max-height: calc(100% - 130px);
}

.delivery_address {
  width: calc(50% - 10px);
  height: auto;
  border: 1px solid rgb(1 41 101 / 10%);
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  float: left;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.delivery_address:nth-child(2n) {
  margin-right: 0;
  margin-left: 10px;
}

.delivery_address:first-child {
  margin-left: 0px;
}

.addresses {
  height: 100%;
  width: calc(100% - 110px);
  font-family: var(--robotoC);
  font-size: 16px;
  font-weight: 300;
  color: #012965;
}

.addresses h2 {
  font-family: var(--robotoC);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.addresses p {
  font-family: var(--robotoC);
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
}

.emptyMsg {
  font-family: var(--Poppins);
  font-size: 14px;
  font-weight: 300;
  color: rgb(1 41 101 / 80%);
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
}

.marketingSeparetor {
  display: flow-root;
  margin-bottom: 12px;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
}

.marketingSeparetor p {
  color: #012965;
  font-weight: 300;
  line-height: 20px;
  font-family: var(--robotoC);
  font-size: 16px;
  font-style: normal;
}

.marketingSeparetor h2 {
  line-height: 20px;
  font-family: var(--robotoC);
  font-size: 16px;
  font-style: normal;
  color: #012965;
  font-weight: 400;
}

.rememberContainer {
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 300;
  display: block;
  position: relative;
  padding-left: 35px;
  color: #012965;
  margin-bottom: 12px;
  margin-top: 8px;
  cursor: pointer;
  line-height: 19px;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.rememberContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 2px;
  background-color: transparent;
  border: 1px solid rgba(255, 0, 0, 0.2);
}

/* On mouse-over, add a grey background color */
.rememberContainer:hover input~.checkmark {
  background-color: #ff0000;
}

/* When the checkbox is checked, add a blue background */
.rememberContainer input:checked~.checkmark {
  background-color: #ff0000;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.rememberContainer input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.rememberContainer .checkmark:after {
  left: 7px;
  top: 3px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.spiner {
  margin-left: 10px;
  display: none;
}

.onProcoess {
  pointer-events: none;
}

.onProcoess .spiner {
  display: inline-block;
}

.errMsg {
  font-family: var(--robotoC);
  font-style: normal;
  font-size: 12px;
  color: #ff0000;
  position: absolute;
  left: 0px;
  bottom: -3px;
}

.inactive,
.inactive:hover {
  opacity: 0.5;
  pointer-events: none;
}

.profileOrdersCont {
  height: calc(100%);
  overflow-y: auto;
  width: 100%;
}

.orderItem {
  width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  border-bottom: 1px solid rgb(1 41 101 / 10%);
  cursor: pointer;
  font-family: var(--Poppins);
  font-size: 14px;
}

.orderCounter {
  width: 5%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #012965;
}

.orderId {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #012965;
}

.orderDate {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #012965;
}

.orderPrice {
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #012965;
}

.accountStatus {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.accountPopup {
  width: 350px;
  height: auto;
  padding: 15px 25px;
  border-radius: 2px;
  box-shadow: 0px 1px 3px rgb(0 0 0 / 25%), 0px 4px 28px rgb(180 180 180 / 25%);
  position: relative;
  background-color: #fff;
}

.accountStatusTitle {
  width: 100%;
  color: #012965;
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 15px;
  font-family: var(--robotoC);
  text-align: center;
}

.accountStatusMsg {
  width: 100%;
  color: #012965;
  text-align: center;
  padding: 10px 0;
  font-family: var(--robotoC);
  font-weight: 300;
  font-size: 16px;
}

.cautionList {
  list-style: disc;
  padding: 0 0 0 20px;
}

.cautionList li {
  font-family: var(--robotoC);
  font-weight: 300;
  font-size: 15px;
  color: #012965;
}


.accountStatusBtns {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 50px);
}

.accountStatusBtns button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  min-width: 100px;
  border-radius: 2px;
  cursor: pointer;
  font-family: var(--robotoC);
  font-weight: 300;
  font-size: 16px;
  background-color: transparent;
}

.cancelAction {
  border: 1px solid #012965;
  color: #012965;
  margin-right: 20px;
}

.accountAction {
  border: 1px solid #ff0000;
  color: #ff0000;
}

.cancelAction:hover {
  background-color: #012965;
  color: #fff;
}

.accountAction:hover {
  background-color: #ff0000;
  color: #fff;
}

.wrapper {
  position: fixed;
  right: 0;
  top: 0;
  width: calc(100% + 0px);
  height: calc(100% - 0px);
  display: flex;
  z-index: 5;
  background-color: rgb(255 255 255 / 85%);
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(2px);
}

.closeCart {
  font-weight: 300;
  border-radius: 2px;
  font-family: var(--robotoC);
  width: 20px;
  height: 20px;
  display: flex;
  font-size: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 25px;
  right: 25px;
  z-index: 99;
}

.countDownPopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(255 255 255 / 94%);
  z-index: 999;
  /* padding: 0% 30%; */
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(2px);
}

.mainContainer {
  width: 550px;
  height: calc(100% - 24%);
  display: flex;
  flex-direction: column;
  margin: 12% 0;
  justify-content: center;
}

.mainContent {
  text-align: justify;
  font-family: var(--robotC);
  font-size: 19px;
  font-weight: 300;
  min-height: 300px;
  line-height: 26px;
  color: #012965;
  background: #FFF;
  padding: 40px 50px;
  box-shadow: 0px 1px 3px rgb(0 0 0 / 25%), 0px 4px 28px rgb(180 180 180 / 25%);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.timerSection {
  width: 100%;
  height: auto;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: transparent;
  padding: 10px 0;
  /* box-shadow: 0px 1px 3px rgb(0 0 0 / 25%), 0px 4px 28px rgb(180 180 180 / 25%); */
}

.psw {
  width: 100%;
  text-align: end;
  display: block;
  line-height: 28px;
}

.psw a {
  font-family: var(--robotoC);
  font-style: normal;
  font-size: 16px;
  font-weight: 300;
  text-decoration: none;
  color: #012965;
}

.profile_float_option {
  min-width: 150px;
  position: absolute;
  right: 90px;
  top: 27px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.icon_style {
  width: 24px;
  height: 18px;
  background: transparent;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.account_section {
  height: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.savedItem {
  width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  border-bottom: 1px solid rgb(1 41 101 / 10%);
  cursor: pointer;
  padding: 0 5px;
}

.savedItem.active {
  background-color: rgb(1 41 101 / 9%);
}

.savedCounter {
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #012965;
}

.savedItemImg {
  width: 75px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #012965;
}

.savedItemImg img {
  max-height: 100%;
}

.savedItemName {
  width: calc(100% - 590px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #012965;
}

.savedQty {
  width: 100px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #012965;
  padding: 0px 5px;
}

.savedPrice {
  width: 230px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #012965;
  padding: 0px 5px;
}

.savedAction {
  width: 180px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #012965;
  padding: 0 5px;
}

.removeSavedItem {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  font-size: 20px;
}

.addItemBtn {
  padding: 0 15px;
  height: 24px;
  border: none;
  border-radius: 2px;
  background-color: #012965;
  color: #fff;
  font-family: var(--robotoC);
  cursor: pointer;
}

.profile_mobile_menu {
  display: none;
}

@media (max-width: 768px) {

  .header_right_bottom {
    height: 100%;
    padding: 0 32px;
  }

  .mobileMenu_icons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .icon_style {
    height: 26px;
    width: 26px;
    border-radius: 50%;
    background-color: rgb(1 41 101 / 10%);
  }

  .deleteAccount {
    position: unset;
    width: 100%;
  }

  .innerContainer {
    height: 100%;
    padding: 0;
  }

  .tabLeft {
    height: calc(100% - 40px);
    width: 100vw;
    padding: 20px 50px;
    background-color: #fff;
    z-index: 9;
    position: fixed;
    right: 0;
    bottom: 0;
    display: none;
  }

  .tabLeft._active {
    display: flow-root;
  }

  .tabRight {
    width: 100%;
    height: calc(100% - 41px);
  }

  .profile_mobile_menu {
    width: 100%;
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 10px;
  }

  .mobile_menu_title {
    padding: 0 10px;
  }

  .mobile_menu_title h2 {
    font-size: 18px;
    font-family: var(--robotoC);
    color: #012965;
  }

  .tabs {
    justify-content: flex-start;
    align-items: center;
    /* flex-direction: column; */
    padding: 10px 10px;
    height: auto;
  }

  .tabName {
    font-size: 15px;
    text-align: center;
    height: 16px;
  }

  .tabBody {
    padding: 10px;
    height: 100%;
  }

  .infoForm {
    width: 100%;
  }

  .delivery_address {
    width: 100%;
    margin: 0 0 10px;
  }

  .delivery_address:nth-child(2n) {
    margin: 0;
  }

  .wrapper {
    width: calc(100%);
    height: calc(100%);
  }

  .closeCart {
    top: 10px;
    right: 10px;
    font-size: 30px;
  }

  .tabRight .closeCart {
    display: none;
  }

  .orderDate {
    width: 65%;
  }

  .orderPrice {
    width: 30%;
  }

  .orderId {
    display: none;
  }

  .savedItem {
    padding: 0 0;
  }

  .savedCounter {
    font-size: 12px;
    width: 25px;
  }

  .savedItemName {
    font-size: 12px;
    width: calc(100% - 290px);
  }

  .savedAction {
    width: 110px;
    padding: 0 5px;
  }

  .addItemBtn {
    padding: 0 5px;
    font-size: 12px;
  }

  .removeSavedItem {
    margin-left: 0px;
  }

  .savedQty {
    width: 49px;
    padding: 0 8px;
    font-size: 12px;
    text-align: center;
  }

  .savedPrice {
    width: 100px;
    padding: 0 8px;
    font-size: 12px;
  }
}