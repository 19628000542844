.sign_in_page_container {
  background: #fff;
  width: 100vw;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 6;
}


.carting_head {
  display: flex;
  padding: 12px;
  height: 85px;
  width: 100%;
  position: relative;
}

.pageTitleSection {
  width: auto;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.pageLogoHolder {
  width: 40px;
  height: 40px;
  border-radius: 2px;
  overflow: hidden;
  float: left;
  margin-right: 15px;
}

.pageLogo {
  width: 100%;
  height: 100%;
}

.pageTitle {
  float: left;
  font-size: 18px;
  font-family: var(--robotoC);
  font-weight: 500;
}

.closeCart {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  color: rgba(255, 0, 0, 0.2);
  border-radius: 2px;
  font-family: var(--robotoC);
  width: 27px;
  height: 27px;
  cursor: pointer;
  top: 25px;
  right: 42px;
  font-size: 40px;
  text-decoration: none;
  background-color: #fff;
}

.signin_partition {
  display: flow-root;
  height: calc(100% - 185px);
  width: 100%;
  background: rgb(255 255 255 / 97%);
  border-radius: 2px;
  padding: 0 100px;
}

.form_content {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.signin_form {
  width: 300px;
  min-height: 286px;
  padding: 35px;
}

.signin_form:last-child {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.signin_form_left {
  width: calc(100% - 300px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F8FAFF;
  height: 100%;
}

.container_login {
  width: 350px;
  padding: 35px;
}

.signin_form_right {
  background-color: #E4EDFF;
  height: 100%;
}

.storeSignin {
  min-height: 331px;
}

.signupLast {
  display: flow-root !important;
}

.signUp_form {
  width: 350px;
  min-height: 286px;
  padding: 35px;
  overflow-y: auto;
}

.countryList {
  width: 270px !important;
}

.inputCont {
  /* padding: 0 40px; */
  float: left;
  width: 100%;
  position: relative;
}

.inputContSignin {
  width: 100%;
  position: relative;
}

.viewPassword {
  position: absolute;
  right: 1px;
  top: 9px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-left: 1px solid rgb(255 0 0 / 10%);
}

.inputCont .viewPassword {
  right: 41px;
}

.signup_title {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  font-family: var(--robotoC);
  font-size: 26px;
  font-style: normal;
  font-weight: 300;
  margin-bottom: 30px;
  color: #012965;
  text-align: left;
}

h1.signup_title span {
  font-size: 14px;
  margin-top: 5px;
}

.signin_title {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  font-family: var(--robotoC);
  font-size: 26px;
  font-style: normal;
  font-weight: 300;
  margin-bottom: 30px;
  color: #012965;
}

h1.signin_title span {
  font-size: 14px;
  margin-top: 5px;
}

.sign-in-page-container form {
  font-size: 16px;
  font-weight: 600;
}

.inputs {
  font-family: var(--robotoC);
  font-style: normal;
  font-size: 16px;
  font-weight: 300;
  height: 42px !important;
  width: 100% !important;
  padding: 8px 20px;
  margin: 8px 0 15px;
  display: inline-block;
  color: #012965;
  letter-spacing: 0.2px;
  border: 1px solid rgb(255 0 0 / 10%) !important;
  box-sizing: border-box;
  border-radius: 2px !important;
}

.codeDropDown {
  border: 1px solid rgb(255 0 0 / 10%) !important;
  background-color: #ffffff !important;
}

.inputs:focus-visible {
  border: 1px solid rgb(255 0 0 / 30%) !important;
  outline: none;
}

.inputs:-webkit-autofill {
  transition: background-color 600000s 0s, color 600000s 0s;
}

.errMsg {
  font-family: var(--robotoC);
  font-style: normal;
  font-size: 12px;
  color: #ff0000;
  position: absolute;
  left: 40px;
  bottom: -3px;
}



.phoneInput {
  margin: 8px 0 15px;
}

.signin_button {
  background-color: #ffffff;
  color: #ff0000;
  height: 24px;
  padding: 0px 20px;
  margin: 8px 0 0;
  border-radius: 2px;
  border: none;
  cursor: pointer;
  width: 100%;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid rgba(255, 0, 0, 0.2);
}

.signin_button:hover {
  background-color: #ff0000;
  color: white;
}

.signin_button:hover {
  opacity: 0.8;
}

.cancelbtn {
  width: auto;
  padding: 10px 18px;
  background-color: #f23065;
}

.signup_button {
  width: auto;
  padding: 10px 18px;
  border: 1px soid f23065;
}

.buttoninactive:hover {
  opacity: 0.5;
}

.imgcontainer {
  text-align: center;
  margin: 24px 0 12px 0;
}

.avatar {
  width: 40%;
  border-radius: 50%;
}

.psw {
  width: 100%;
  text-align: end;
  display: block;
  line-height: 28px;
}

.psw a {
  font-family: var(--robotoC);
  font-style: normal;
  font-size: 16px;
  font-weight: 300;
  text-decoration: none;
  color: #012965;
}

.psw a:hover {
  text-decoration: underline;
}

.rememberContainer {
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 300;
  display: block;
  position: relative;
  padding-left: 35px;
  color: #012965;
  margin-bottom: 12px;
  margin-top: 8px;
  cursor: pointer;
  line-height: 19px;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.rememberContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 2px;
  background-color: transparent;
  border: 1px solid rgba(255, 0, 0, 0.2);
}

/* On mouse-over, add a grey background color */
.rememberContainer:hover input~.checkmark {
  background-color: #ff0000;
}

/* When the checkbox is checked, add a blue background */
.rememberContainer input:checked~.checkmark {
  background-color: #ff0000;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.rememberContainer input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.rememberContainer .checkmark:after {
  left: 7px;
  top: 3px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.signintextMsg {
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 30px;
  color: #012965;
}

.spiner {
  margin-left: 10px;
  display: none;
}

.onProcoess {
  pointer-events: none;
}

.onProcoess .spiner {
  display: inline-block;
}

button[disabled] {
  background: #ffffff;
}

.inputLabel {
  color: #012965;
  font-size: 15px;
  font-family: var(--robotoC);
  margin-bottom: 10px;
  font-weight: 300;
  display: flex;
  justify-content: space-between;
}

.resendBTN {
  cursor: pointer;
}

.socialContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 40px 40px;
}

.socialIcon {
  width: 50px;
  height: 50px;
  background-color: antiquewhite;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 10px;
  cursor: pointer;
}

.loginText_bottom {
  width: 100%;
  text-align: end;
  display: block;
  line-height: 28px;
  margin-top: 8px;
}

.loginText_bottom a:hover {
  text-decoration: underline;
}

.loginText_bottom a {
  font-family: var(--robotoC);
  font-style: normal;
  font-size: 16px;
  font-weight: 300;
  text-decoration: none;
  color: #012965;
  cursor: pointer;
}

.otp_section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sub_title {
  text-align: center;
  font-size: 15px;
  font-weight: 300;
  font-family: var(--robotoC);
  margin-bottom: 20px;
  color: #012965;
  line-height: 24px;
}

.opt_label {
  text-align: center;
  font-size: 15px;
  font-weight: 300;
  font-family: var(--robotoC);
  color: #012965;
  line-height: 18px;
}

.opt_input_container {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  padding: 10px 0;
  margin-bottom: 30px;
  position: relative;
  width: 240px;
}

.opt_input {
  border-radius: 2px;
  border: 1px solid rgb(255 0 0 / 30%);
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: 18px;
  color: #012965;
}
.opt_input:focus-visible {
  outline: none;
}

.opt_resend {
  text-align: end;
  font-size: 12px;
  font-weight: 200;
  font-family: var(--robotoC);
  color: #fff;
  line-height: 14px;
  position: absolute;
  width: auto;
  right: 6px;
  bottom: -14px;
  border-radius: 2px;
  cursor: pointer;
}

.resendBTN {
  text-align: end;
  font-size: 12px;
  font-weight: 300;
  font-family: var(--robotoC);
  color: #ff0000;
  line-height: 14px;
  width: auto;
  border: 1px solid rgb(255 0 0 / 30%);
  padding: 2px 5px;
  border-radius: 2px;
  cursor: pointer;
}

.resendBTN:hover {
  color: #fff;
  background-color: #ff0000;
}

.resendText {
  text-align: end;
  font-size: 12px;
  font-weight: 300;
  font-family: var(--robotoC);
  color: #012965;
  line-height: 14px;
  width: auto;
  background-color: transparent;
  padding: 2px 5px;
  border-radius: 2px;
  cursor: pointer;
}

/* Change styles for span and cancel button on extra small screens */
@media (max-width: 768px) {
  .carting_head {
    height: 40px;
  }

  .signin_partition {
    padding: 0 12px;
    height: calc(100% - 40px);
  }

  .form_content {
    display: flow-root;
    overflow-y: auto;
  }

  .signup_content {
    display: flex;
    padding: 20px;
  }

  .container_login {
    width: 100%;
  }

  .signup_title {
    width: 100%;
    margin-bottom: 20px;
  }

  .signUp_form {
    width: 100%;
    padding: 0 30px;
  }

  .inputCont {
    width: 100%;
  }

  .closeCart {
    top: 10px;
    right: 10px;
    font-size: 30px;
    width: 20px;
    height: 20px;
  }

  .popup_nav {
    padding: 5px 12px;
    height: 20px;
  }

  .signin_title {
    margin-bottom: 20px;
  }

  .inputs {
    margin-top: 0;
  }

  .viewPassword {
    top: 0;
  }

  .psw {
    line-height: 20px;
  }

  .signin_button {
    background-color: #ff0000 !important;
    color: #ffffff;
  }

  .signin_form {
    border-right: none;
    padding: 20px;
    width: 100%;
    margin: 0 auto;
    display: flow-root;
  }

  .signin_form:first-child {
    margin-bottom: 30px;
    display: flex;
  }

  .signin_form:last-child {
    min-height: 182px;
    margin-top: 30px;
    position: relative;
  }

  .signin_form:last-child::before {
    content: '';
    height: 1px;
    background-color: rgba(255, 0, 0, 0.2);
    width: calc(100% + 40px);
    position: absolute;
    top: -30px;
    left: -20px;
  }

  .signintextMsg {
    margin-bottom: 20px;
  }

}